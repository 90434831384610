import heroImg from '../assets/hero-image.png';
import GCS from '../assets/certificate.png';
import bg from '../assets/hero-back.png';
import Button from './reusable/Button';

export default function HeroSection() {
    return (
        <section className="relative h-screen sm:h-[70vh] md:h-[80vh] overflow-hidden">
            <div className="absolute inset-0 z-0 ">
                <img
                    src={bg}
                    alt="Background"
                    className="object-cover w-full h-full opacity-100"
                    priority
                />
            </div>

            <div className=" z-10 h-full w-full mx-auto flex md:justify-center items-center">
                <div className="z-10 pt-12 lg:pt-12 px-4 md:px-8 w-full lg:w-1/2">
                    <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-5xl">
                        Secure Your Future with{" "}
                        <span className="block">Cutting-Edge Cybersecurity Solutions</span>
                    </h1>
                    <p className="mb-8 text-lg text-gray-600">
                        Protecting your business in real-time against evolving cyber threats.
                    </p>
                    <div className="mt-6 flex flex-col md:flex-row items-start md:items-center gap-6">
                        <div className="">
                            <a href="/#products">
                                <Button>Explore Our Solutions</Button>
                            </a>
                        </div>
                        <p className="block md:hidden text-xl text-gray-600 max-w-sm text-left">
                            Tested and Endorsed by Global Cyber Security Experts
                        </p>
                        <div>
                            <img
                                src={GCS}
                                alt="GCSF Certification"
                                width={80}
                                height={80}
                            />
                        </div>
                        <p className="hidden md:block text-xl text-gray-400 max-w-sm text-left">
                            Tested and Endorsed by Global Cyber Security Experts
                        </p>
                    </div>
                </div>
                {/* <div className="h-full flex flex-col justify-center p-4 md:p-6">
                    <div className="pt-14 sm:pt-0">
                        <div className="text-left">
                            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 md:text-5xl">
                                <span className="block">Secure Your Future</span>
                                <span className="block text-red-600">with Cutting-Edge</span>
                                <span className="block">Cybersecurity Solutions</span>
                            </h1>
                            <p className="mt-3 text-base sm:text-xl text-gray-500 sm:mt-5 sm:max-w-xl md:mt-5 lg:mx-0">
                                Protecting your business in real-time against evolving cyber threats.
                            </p>

                            <div className="mt-6 flex flex-col md:flex-row items-start md:items-center gap-6">
                                <div className="flex-grow">
                                    <a href="/#products">
                                        <Button>Explore Our Solutions</Button>
                                    </a>
                                </div>
                                <p className="block md:hidden text-xl text-gray-600 max-w-sm text-left">
                                    Tested and Endorsed by Global Cyber Security Experts
                                </p>
                                <div>
                                    <img
                                        src={GCS}
                                        alt="GCSF Certification"
                                        width={80}
                                        height={80}
                                    />
                                </div>
                                <p className="hidden md:block text-xl text-gray-400 max-w-sm text-left">
                                    Tested and Endorsed by Global Cyber Security Experts
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="hidden lg:block relative h-full w-full lg:w-1/2">
                    <img
                        src={heroImg}
                        alt="Cityscape with tall skyscraper"
                        className="object-cover w-full h-full"
                        width={1080}
                        height={1080}
                        priority
                    />
                </div>
            </div>
        </section>
        //  <section className='h-[85vh] mx-auto'>
        //             <div className='grid grid-cols-1 md:grid-cols-2 place-items-center'>
        //                 <div><h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-5xl">
        //                     Secure Your Future with{" "}
        //                     <span className="block">Cutting-Edge Cybersecurity Solutions</span>
        //                 </h1>
        //                     <p className="mb-8 text-lg text-gray-600">
        //                         Protecting your business in real-time against evolving cyber threats.
        //                     </p>
        //                     <div className="mt-6 flex flex-col md:flex-row items-start md:items-center gap-6">
        //                         <div className="">
        //                             <a href="/#products">
        //                                 <Button>Explore Our Solutions</Button>
        //                             </a>
        //                         </div>
        //                         <p className="block md:hidden text-xl text-gray-600 max-w-sm text-left">
        //                             Tested and Endorsed by Global Cyber Security Experts
        //                         </p>
        //                         <div>
        //                             <img
        //                                 src={GCS}
        //                                 alt="GCSF Certification"
        //                                 width={80}
        //                                 height={80}
        //                             />
        //                         </div>
        //                         <p className="hidden md:block text-xl text-gray-400 max-w-sm text-left">
        //                             Tested and Endorsed by Global Cyber Security Experts
        //                         </p>
        //                     </div>
        //                 </div>
        //                 <div className='hidden lg:block w-full h-full'>
        //                     <img
        //                         src={heroImg}
        //                         alt="City skyline at night"
        //                         width={800}
        //                         height={1000}
        //                         className="h-full w-full object-cover"
        //                         priority
        //                     />
        //                 </div>
        //             </div>
        //         </section>
    )
}



// export default function Component() {
//     return (
//         <div className="relative h-[80vh] w-full overflow-hidden">



//             {/* Main content container */}
//             <div className="relative mx-auto h-full px-4 sm:px-6 lg:px-8">
//                 <div className="grid h-full items-center lg:grid-cols-2">
//                     {/* Left content */}
// <div className="z-10 max-w-xl pt-8 lg:pt-0">
//     <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-5xl">
//         Secure Your Future with{" "}
//         <span className="block">Cutting-Edge Cybersecurity Solutions</span>
//     </h1>
//     <p className="mb-8 text-lg text-gray-600">
//         Protecting your business in real-time against evolving cyber threats.
//     </p>
//     <div className="mb-8">
//         <Button href={"#products"} size='md'>
//             Explore Our Solutions
//         </Button>
//     </div>
//     <div className="flex items-center gap-4">
//         <div className="h-20 w-20 flex-shrink-0">
//             <img
//                 src={GCS}
//                 alt="GCSF Certification"
//                 width={80}
//                 height={80}
//                 className="h-full w-full object-contain"
//             />
//         </div>
//         <p className="text-sm text-gray-600">
//             Tested and Endorsed by Global Cyber Security Experts
//         </p>
//     </div>
// </div>

//                     {/* Right image */}
//                     <div className="hidden lg:block absolute right-0 top-0 h-full w-1/2">
//                         <img
//                             src={heroImg}
//                             alt="City skyline at night"
//                             width={800}
//                             height={1000}
//                             className="h-full w-full object-cover"
//                             priority
//                         />
//                     </div>
//                 </div>
//             </div>
//         </div >
//     )
// }