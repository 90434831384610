export default function WhyChooseUs({
    layout,
    title,
    mainHeading,
    description,
    benefits,
    columns = 3  // Default to 3 columns if not specified
}) {
    // Set up the column classes based on the `columns` prop
    const columnClasses = `grid gap-8 ${columns === 1 ? 'grid-cols-1' : columns === 2 ? 'md:grid-cols-2' : columns === 4 ? 'md:grid-cols-4' : 'md:grid-cols-3'}`;

    return (
        <section className="py-12 md:py-20 bg-white">
            <div className=" mx-auto px-4 sm:px-6 md:px-8">
                <h2 className="text-red-600 font-semibold mb-2">{title}</h2>

                {layout === 'layout1' ? (
                    <div className={`mb-12 ${description && "flex justify-between flex-wrap"}`}>
                        <h3 className="max-w-lg text-4xl font-bold mb-4">{mainHeading}</h3>
                        {description && <p className="text-gray-600 max-w-2xl">{description}</p>}
                    </div>
                ) : (
                    <h3 className="text-4xl font-bold mb-12">{mainHeading}</h3>
                )}

                <div className={columnClasses}>
                    {benefits.map((benefit, index) => (
                        <div key={index} className="flex flex-col items-start">
                            <div className="flex items-center">
                                {benefit.icon && <img src={benefit.icon} className="w-16 h-16 text-red-600" alt="icon" />}
                            </div>
                            <h4 className="text-xl font-semibold mt-4 mb-2">{benefit.title}</h4>
                            <p className="text-gray-600">{benefit.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}


// export default function WhyChooseUs({
//     layout,
//     title,
//     mainHeading,
//     description,
//     benefits
// }) {
//     return (
//         <section className="py-12 bg-white">
//             <div className="container mx-auto px-8">
//                 <h2 className="text-red-600 font-semibold mb-2">{title}</h2>

//                 {layout === 'layout1' ? (
//                     <div className={`mb-12 ${description && " flex justify-between flex-wrap"}`}>
//                         <h3 className="max-w-lg text-4xl font-bold mb-4">{mainHeading}</h3>
//                         {description && <p className="text-gray-600 max-w-2xl">{description}</p>}
//                     </div>
//                 ) : (
//                     <h3 className="text-4xl font-bold mb-12">{mainHeading}</h3>
//                 )}

//                 <div className={`grid gap-8 ${layout === 'layout1' ? 'md:grid-cols-3' : 'md:grid-cols-2 lg:grid-cols-3'}`}>
//                     {benefits.map((benefit, index) => (
//                         <div key={index} className="flex flex-col items-start">
//                             <div className="flex items-center">
//                                 {benefit.icon && <img src={benefit.icon} className="w-16 h-16 text-red-600" alt="icon" />}
//                             </div>
//                             <h4 className="text-xl font-semibold mt-4 mb-2">{benefit.title}</h4>
//                             <p className="text-gray-600">{benefit.description}</p>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </section>
//     );
// }
