import React, { useState, useRef, useEffect } from 'react';

function Dropdown({ buttonContent, children }) {
    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef(null);

    // Close dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsActive(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div ref={dropdownRef} className="relative">
            {/* Button to toggle dropdown */}
            <button
                onClick={() => setIsActive(!isActive)}
                className="inline-flex items-center p-2 text-gray-600  hover:text-gray-700"
            >
                {buttonContent}
            </button>

            {/* Dropdown menu */}
            {isActive && (
                <div className="absolute left-0 z-10 mt-2 w-64 rounded-md border border-gray-100 bg-white shadow-lg" role="menu">
                    <div className="p-2" onClick={() => setIsActive(!isActive)}>
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Dropdown;
