import React from 'react'
import aboutbg from "../assets/about.png"

// const NetworkBackground = () => (
//     <svg
//         className="absolute inset-0 w-full h-full"
//         xmlns="http://www.w3.org/2000/svg"
//         preserveAspectRatio="xMidYMid slice"
//         viewBox="0 0 1920 1080"
//     >
//         <defs>
//             <radialGradient id="dots" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
//                 <stop offset="0%" stopColor="#FF6B00" stopOpacity="0.3" />
//                 <stop offset="100%" stopColor="#FF6B00" stopOpacity="0" />
//             </radialGradient>
//         </defs>
//         <rect width="100%" height="100%" fill="#1A1A2E" />
//         <g fill="url(#dots)">
//             {[...Array(200)].map((_, i) => (
//                 <circle
//                     key={i}
//                     cx={Math.random() * 1920}
//                     cy={Math.random() * 1080}
//                     r={Math.random() * 2 + 1}
//                 />
//             ))}
//         </g>
//         <g stroke="#FF6B00" strokeWidth="0.5" strokeOpacity="0.2">
//             {[...Array(40)].map((_, i) => (
//                 <line
//                     key={i}
//                     x1={Math.random() * 1920}
//                     y1={Math.random() * 1080}
//                     x2={Math.random() * 1920}
//                     y2={Math.random() * 1080}
//                 />
//             ))}
//         </g>
//     </svg>
// )

export default function AboutSPIO() {
    return (
        <section id='about' className='relative bg-[#1A1A2E]  py-16 md:py-20 flex items-center scroll-mt-16'
            style={{
                backgroundImage: `url(${aboutbg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"

            }}>
            {/* <NetworkBackground /> */}
            <div className="z-10 w-11/12 max-w-3xl mx-auto px-4 lg:px-8 text-white flex-shrink-0">
                <h2 className="text-sm font-semibold tracking-wide uppercase mb-2">Who We ARE</h2>
                <h1 className="text-2xl sm:text-3xl lg:text-5xl font-bold mb-6">About SPIO</h1>
                <div className="space-y-6 text- sm:text-lg">
                    <p>
                        SPIO Labs is a multi-domain specialty firm with Product & Services spread
                        across Cybersecurity, Social Intelligence and Unified communications.
                    </p>
                    <p>
                        We are a team of senior technocrats each with experience spanning over
                        two decades with global fortune 500 companies building a stack of world
                        class technology platforms tailor made to the needs of Global Enterprises.
                    </p>
                    <p>
                        With world class product engineering at the core of our DNA, we have built
                        Class Leading Enterprise Grade Solutions that are truly Un-Paralleled.
                    </p>
                </div>
            </div>
            <div className="flex-grow bg-transparent"></div>
        </section>
    )
}
