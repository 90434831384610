
// import { useState } from 'react'
// import Button from './Button'
// import contactImg from "../../assets/contact.png"

// export default function ContactForm() {
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         message: '',
//         acceptTerms: false
//     })

//     const [errors, setErrors] = useState({})

//     const handleChange = (e) => {
//         const { name, value, type, checked } = e.target
//         setFormData(prevData => ({
//             ...prevData,
//             [name]: type === 'checkbox' ? checked : value
//         }))
//     }

//     const validateForm = () => {
//         let newErrors = {}
//         if (!formData.name.trim()) newErrors.name = 'Name is required'
//         if (!formData.email.trim()) newErrors.email = 'Email is required'
//         else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid'
//         if (!formData.message.trim()) newErrors.message = 'Message is required'
//         if (!formData.acceptTerms) newErrors.acceptTerms = 'You must accept the terms'
//         setErrors(newErrors)
//         return Object.keys(newErrors).length === 0
//     }

//     const handleSubmit = (e) => {
//         e.preventDefault()
//         if (validateForm()) {
//             console.log('Form submitted:', formData)
//             // Here you would typically send the data to your server
//         }
//     }

//     return (
//         <div id='contact' className="container mx-auto p-6 px-4 md:px-12 flex flex-col md:flex-row items-center md:items-start gap-8 py-8 md:py-16 scroll-mt-20">
//             <div className="md:w-1/2">
//                 <h1 className="text-3xl md:text-5xl font-bold mb-4">Contact us</h1>
//                 <p className="mb-6 text-gray-600">
//                     We're here to help secure your business. Reach out to learn more about our services or ask any questions. Our team is ready to assist you.
//                 </p>
//                 <img
//                     src={contactImg}
//                     alt="Customer support representative"
//                     className="rounded-lg"
//                 />
//             </div>
//             <form onSubmit={handleSubmit} className="w-full md:w-1/2 space-y-4">
//                 <div>
//                     <label htmlFor="name" className="block mb-1 font-medium">Name</label>
//                     <input
//                         type="text"
//                         id="name"
//                         name="name"
//                         value={formData.name}
//                         onChange={handleChange}
//                         className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                     />
//                     {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
//                 </div>
//                 <div>
//                     <label htmlFor="email" className="block mb-1 font-medium">Email</label>
//                     <input
//                         type="email"
//                         id="email"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                     />
//                     {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
//                 </div>
//                 <div>
//                     <label htmlFor="message" className="block mb-1 font-medium">Message</label>
//                     <textarea
//                         id="message"
//                         name="message"
//                         value={formData.message}
//                         onChange={handleChange}
//                         rows={4}
//                         className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                         placeholder="Type your message..."
//                     ></textarea>
//                     {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
//                 </div>
//                 <div className="flex items-center">
//                     <input
//                         type="checkbox"
//                         id="acceptTerms"
//                         name="acceptTerms"
//                         checked={formData.acceptTerms}
//                         onChange={handleChange}
//                         className="mr-2"
//                     />
//                     <label htmlFor="acceptTerms">I accept the Terms</label>
//                 </div>
//                 {errors.acceptTerms && <p className="text-red-500 text-sm">{errors.acceptTerms}</p>}
//                 {/* <button
//                     type="submit"
//                     className="w-fit bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-300"
//                 >
//                     Submit
//                 </button> */}
//                 <Button type="submit" className='!mt-10'>
//                     Submit
//                 </Button>
//             </form>
//         </div>
//     )
// }

import { useState } from 'react';
import Button from './Button';
import contactImg from "../../assets/contact.png";
// import { Link } from 'react-router-dom';

export default function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        acceptTerms: false
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.name.trim()) newErrors.name = 'Name is required';
        if (!formData.email.trim()) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
        if (!formData.message.trim()) newErrors.message = 'Message is required';
        if (!formData.acceptTerms) newErrors.acceptTerms = 'You must accept the terms';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage("");
        setErrorMessage("");

        if (validateForm()) {
            setLoading(true);

            try {
                const response = await fetch("https://nodedevapi.spiolabs.com/auth/sendEmailSpio", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(formData)
                });

                if (response.ok) {
                    setSuccessMessage("Your message has been sent successfully!");
                    setFormData({ name: '', email: '', message: '', acceptTerms: false });
                } else {
                    const errorData = await response.json();
                    setErrorMessage(errorData.message || "Something went wrong. Please try again.");
                }
            } catch (error) {
                setErrorMessage("An error occurred. Please try again later.");
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div id='contact' className=" mx-auto p-6 px-4 sm:px-6 md:px-8 flex flex-col md:flex-row items-center md:items-start gap-8 py-8 md:py-16 scroll-mt-16">
            <div className="md:w-1/2">
                <h1 className="text-3xl md:text-5xl font-bold mb-4">Contact us</h1>
                <p className="mb-6 text-gray-600">
                    We're here to help secure your business. Reach out to learn more about our services or ask any questions. Our team is ready to assist you.
                </p>
                <img
                    src={contactImg}
                    alt="Customer support representative"
                    className="rounded-lg"
                />
            </div>
            <form onSubmit={handleSubmit} className="w-full md:w-1/2 space-y-4">
                <div>
                    <label htmlFor="name" className="block mb-1 font-medium">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                </div>
                <div>
                    <label htmlFor="email" className="block mb-1 font-medium">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                </div>
                <div>
                    <label htmlFor="message" className="block mb-1 font-medium">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows={4}
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder="Type your message..."
                    ></textarea>
                    {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
                </div>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="acceptTerms"
                        name="acceptTerms"
                        checked={formData.acceptTerms}
                        onChange={handleChange}
                        className="mr-2 cursor-pointer"
                    />
                    {/* <label htmlFor="acceptTerms" className='cursor-pointer'>I accept the <span onClick={() => window.open('/terms-of-service', '_blank')} className='cursor-pointer underline'>Terms</span></label> */}
                    <label htmlFor="acceptTerms" className='cursor-pointer'>I accept the <a href="/terms-of-service" target='_blank' className='underline'>Terms</a></label>
                </div>
                {errors.acceptTerms && <p className="text-red-500 text-sm">{errors.acceptTerms}</p>}

                {successMessage && <p className="text-green-500 text-sm mt-2">{successMessage}</p>}
                {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}

                <Button type="submit" className='!mt-10' disabled={!formData.acceptTerms || loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                </Button>
            </form>
        </div>
    );
}
