import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import Accordion from "./reusable/Accordion"
import logo from "../assets/spio-logo.svg"
import Button from './reusable/Button';
import Dropdown from './reusable/Dropdown';
import { Link } from "react-router-dom";

const NavItem = ({ item }) => {
    return (
        <div>
            <Dropdown
                buttonContent={
                    <>
                        <span className="mr-2"><a href={item.href}>{item.name}</a></span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </>
                }
            >
                {item.subItems && item.subItems.map((subItem) => (
                    <Link
                        key={subItem.name}
                        to={subItem.href}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                    >
                        {subItem.name}
                    </Link>
                ))}
            </Dropdown>
        </div>
    );
};

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const navItems = [
        {
            name: 'Products',
            href: '/#products',
            subItems: [
                { name: 'EDR', href: '/product/edr' },
                { name: 'OT Security', href: '/product/ot-security' },
                { name: 'PAM', href: '/product/pam' },
                { name: 'Identity Assurance', href: '/product/identity-assurance' },
            ],
        },
        {
            name: 'Platforms',
            href: '/#platforms',
            subItems: [
                { name: 'SPIOFI', href: '/platforms/spiofi' },
                { name: 'Social Up', href: '/platforms/social-up' },
                { name: 'AI Telephonic Voice and Chat', href: '/platforms/ai-telephonic-voice-and-chat' },
            ],
        },
        {
            name: 'Services',
            href: '/#services',
            subItems: [{ name: 'Cyber Security Consulting', href: '/services/cyber-security-consulting' }],
        },
    ];

    return (
        <nav className="bg-white shadow-sm fixed w-full z-50">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between py-4">
                    <div className="flex items-center gap-x-8">
                        <a href="/" className="flex-shrink-0">
                            <img className="h-8 w-auto" src={logo} alt="SPIO logo" />
                        </a>
                        <div className='hidden lg:flex items-center space-x-6'>
                            <Link to="/#about" className='block px-4 py-2 text-abse text-gray-700'>About</Link>

                            {navItems.map((item) => (
                                <NavItem key={item.name} item={item} />
                            ))}
                            <Link to="/#contact" className='block px-4 py-2 text-base text-gray-700'>Contact Us</Link>
                        </div>
                    </div>
                    <div className="hidden lg:flex items-center space-x-4">
                        <Button href={"/#products"}>
                            Explore Our Solutions
                        </Button>
                    </div>
                    <div className="lg:hidden flex items-center">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="inline-flex items-center justify-center p-2 rounded-md text-black/90 hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
                        >
                            <span className="sr-only">Open main menu</span>
                            {isOpen ? <X className="block h-6 w-6" /> : <Menu className="block h-6 w-6" />}
                        </button>
                    </div>
                </div>
            </div>

            {isOpen && (
                <div className="lg:hidden absolute w-full bg-gray-100 z-50 shadow-md pb-3">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <span onClick={() => setIsOpen(!isOpen)}>
                            <Link to="/#about" className='block px-3 py-3 text-base text-gray-700 hover:text-gray-900'>About</Link>
                        </span>
                        {navItems.map((item) => (
                            <Accordion key={item.name} item={item} isOpen={isOpen} setIsOpen={setIsOpen} />
                        ))}
                        <span onClick={() => setIsOpen(!isOpen)}>
                            <Link to="/#contact" className='block px-3 py-3 text-base text-gray-700 hover:text-gray-900'>Contact Us</Link>
                        </span>
                    </div>
                    <div className="pt-4 pb-6 px-5">
                        <Button href={"/#products"}>
                            <span onClick={() => setIsOpen(!isOpen)}>
                                Explore Our Solutions
                            </span>
                        </Button>
                    </div>
                </div>
            )}
        </nav>
    );
}
