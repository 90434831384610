import Button from './Button';

const imgSizeStyles = {
    style1: 'w-full h-[200px] md:h-[250px] object-cover rounded-lg',
    style2: 'w-full h-[300px] md:h-[400px] object-contain rounded-lg',
    style3: 'w-full object-cover rounded-lg',
};

export default function SectionWithImageAndScroll({
    imageSrc = "",
    imageAlt = "Section image",
    title = "",
    subtitle = "",
    description = "",
    buttonText = "Get Started",
    onButtonClick,
    buttonLink,
    features = [],
    imageSize = 'style2', // default image size
    imagePosition = 'left', // default image position
    cardSize = 'w-64 sm:w-[320px] lg:w-[580px]', // default card size
    bgColor = ""
}) {
    return (
        <div className={`${bgColor}`}>
            <section className="mx-auto px-6 md:px-8 py-6 md:py-12 ">
                <div className={`flex flex-col md:flex-row items-center gap-8 ${imagePosition === 'right' ? 'md:flex-row-reverse' : ''}`}>
                    <div className="container w-full md:w-1/2">
                        <div>
                            <img
                                src={imageSrc}
                                alt={imageAlt}
                                className={`${imgSizeStyles[imageSize]}`}
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 md:space-y-4 space-y-2">
                        <p className="text-red-600 font-semibold ">{subtitle}</p>
                        <h1 className="text-3xl md:text-5xl font-bold leading-tight">
                            {title}
                        </h1>
                        <p className="text-gray-600 pb-5">
                            {description}
                        </p>
                        <a href={buttonLink}>
                            <Button onClick={onButtonClick}>
                                {buttonText}
                            </Button>
                        </a>
                    </div>
                </div>
            </section>

            <section className="py-6 lg:py-12">
                <div className=" mx-auto px-6">
                    <div className="overflow-x-auto pb-6 -mx-4 px-4 no-scrollbar">
                        <div className="flex space-x-3 md:space-x-6 w-full lg:w-[calc(3*465px+2*1.5rem)]">
                            {features.map((feature, index) => (
                                <div key={index} className={`bg-[#f2f2f2] p-6 rounded-lg ${cardSize} flex-shrink-0`}>
                                    <img src={feature.icon} className="w-12 h-12 text-red-600 mb-4" alt='icon' />
                                    <h3 className="text-xl font-semibold">{feature.title}</h3>
                                    <h4 className="text-xl font-medium mb-2">{feature.subtitle}</h4>
                                    <p className="text-gray-600">
                                        {feature.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}





// import { Clock, Wifi, Shield } from "lucide-react"
// import Button from './Button'
// import p from "../../assets/protect-your-business.png"

// export default function SectionWithImageAndScroll() {
//     return (
//         <div className="bg-white">
//             <section className="container mx-auto px-4 py-12 md:py-24">
//                 <div className="flex flex-col md:flex-row items-center gap-8">
//                     <div className="w-full md:w-1/2">
//                         <div className="">
//                             <img src={p} alt="" className="w-full" />
//                         </div>
//                     </div>
//                     <div className="w-full md:w-1/2 space-y-6">
//                         <p className="text-red-600 font-semibold">Monitoring & Control</p>
//                         <h1 className="text-4xl md:text-5xl font-bold leading-tight">
//                             See All,<br />Miss None
//                         </h1>
//                         <p className="text-gray-600">
//                             Achieve unparalleled oversight with real-time analytics and
//                             adaptive responses, ensuring that every aspect of your
//                             cybersecurity is under control.
//                         </p>
//                         <Button>
//                             Get Started
//                         </Button>
//                     </div>
//                 </div>
//             </section>

//             <section className=" py-12">
//                 <div className="container mx-auto px-4">
//                     <div className="overflow-x-auto pb-6 -mx-4 px-4 no-scrollbar">
//                         <div className="flex space-x-6 w-[calc(4*380px+3*1.5rem)]">
//                             <div className="bg-white p-6 rounded-lg shadow-md">
//                                 <Clock className="w-12 h-12 text-red-600 mb-4" />
//                                 <h3 className="text-xl font-semibold mb-2">Real-Time Oversight</h3>
//                                 <h4 className="text-lg font-medium mb-2">Your Security Dashboard</h4>
//                                 <p className="text-gray-600">
//                                     When a security incident happens, every second counts.
//                                     We offer real-time data recording and analysis, enabling
//                                     you to act swiftly and decisively.
//                                 </p>
//                             </div>

//                             <div className="bg-white p-6 rounded-lg shadow-md">
//                                 <Wifi className="w-12 h-12 text-red-600 mb-4" />
//                                 <h3 className="text-xl font-semibold mb-2">IoT Security</h3>
//                                 <h4 className="text-lg font-medium mb-2">Covering All Bases</h4>
//                                 <p className="text-gray-600">
//                                     IoT devices are often the weakest link in your network, but
//                                     they don't have to be. We extend our security measures to
//                                     cover your IoT devices.
//                                 </p>
//                             </div>

//                             <div className="bg-white p-6 rounded-lg shadow-md">
//                                 <Shield className="w-12 h-12 text-red-600 mb-4" />
//                                 <h3 className="text-xl font-semibold mb-2">Safe Remote Work</h3>
//                                 <h4 className="text-lg font-medium mb-2">in Hybrid Environments</h4>
//                                 <p className="text-gray-600">
//                                     Remote work introduces new security challenges. Our solution
//                                     helps you maintain a secure perimeter, even when your team is
//                                     working from home.
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     )
// }

// import Button from './Button';

// const imgSizeStyles = {
//     style1: 'h-[200px] md:h-[250px]',
//     style2: 'w-full h-[300px] md:h-[400px] object-contain rounded-lg',
//     style3: 'w-full object-cover rounded-lg',
// };

// export default function SectionWithImageAndScroll({
//     imageSrc = "",
//     imageAlt = "Section image",
//     title = "",
//     subtitle = "",
//     description = "",
//     buttonText = "Get Started",
//     onButtonClick,
//     features = [],
//     imageSize = 'style2', // default image size
//     imagePosition = 'left', // default image position
//     cardCount = 3, // default number of cards
// }) {
//     return (
//         <div className="bg-white">
//             <section className="container mx-auto px-4 py-12 md:py-24">
//                 <div className={`flex flex-col md:flex-row items-center gap-8 ${imagePosition === 'right' ? 'md:flex-row-reverse' : ''}`}>
//                     <div className="w-full md:w-1/2">
//                         <div>
//                             <img
//                                 src={imageSrc}
//                                 alt={imageAlt}
//                                 className={`${imgSizeStyles[imageSize]}`}
//                             />
//                         </div>
//                     </div>
//                     <div className="w-full md:w-1/2 space-y-6">
//                         <p className="text-red-600 font-semibold">{subtitle}</p>
//                         <h1 className="text-4xl md:text-5xl font-bold leading-tight">
//                             {title}
//                         </h1>
//                         <p className="text-gray-600">
//                             {description}
//                         </p>
//                         <Button onClick={onButtonClick}>
//                             {buttonText}
//                         </Button>
//                     </div>
//                 </div>
//             </section>

//             {/* Scrollable Features Section */}
//             <section className="py-6 lg:py-12">
//                 <div className="container mx-auto px-6">
//                     <div className="overflow-x-auto pb-6 -mx-4 px-4 no-scrollbar">
//                         <div className={`flex space-x-6 w-full lg:w-[calc(${cardCount}*220px+${(cardCount - 1) * 1.5}rem)]`}>
//                             {features.slice(0, cardCount).map((feature, index) => (
//                                 <div key={index} className="bg-white p-6 rounded-lg shadow-md w-64 md:w-1/2 flex-shrink-0">
//                                     <feature.icon className="w-12 h-12 text-red-600 mb-4" />
//                                     <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
//                                     <h4 className="text-lg font-medium mb-2">{feature.subtitle}</h4>
//                                     <p className="text-gray-600">
//                                         {feature.description}
//                                     </p>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     );
// }