import { Link } from "react-router-dom"
import SPIOFI from "../assets/platform1.png"
import Social from "../assets/platform2.png"
import AI from "../assets/platform3.png"

export default function Platform() {
    const platforms = [
        {
            title: "SPIOFI",
            subtitle: "Future Ready Wi-Fi Management",
            description: "Navigate the future of connectivity with SPIOFI, simplifying Wi-Fi management and enhancing security.",
            // and insights
            image: SPIOFI,
            src: "/platforms/spiofi"
        },
        {
            title: "Social Up",
            subtitle: "Elevate your social presence effortlessly",
            description: "Automate reviews, manage your reputation, and engage audiences seamlessly from our intuitive dashboard.",
            image: Social,
            src: "/platforms/social-up"
        },
        {
            title: "AI Telephonic Voice and Chat",
            subtitle: "Human-Like Conversations",
            // , Automated Efficiency
            description: "Transform customer interactions with AI-powered voice and chat solutions, elevating service and satisfaction.",
            image: AI,
            src: "/platforms/ai-telephonic-voice-and-chat"
        }
    ]

    return (
        <div id="platforms" className="mx-auto px-4 md:px-8 py-10 md:py-16 scroll-mt-12">
            <div className="md:text-center mb-12 max-w-2xl text-left md:mx-auto">
                <h2 className="text-red-600 font-semibold mb-2">Platforms</h2>
                <h1 className="text-3xl sm:text-5xl font-bold mb-4">Powerful Platforms for Seamless Connectivity</h1>
                <p className="text-gray-600 text-base sm:text-lg pt-3">
                    Our platforms boost efficiency, security, and engagement: SPIOFI for Wi-Fi, Social Up for reputation, and SPIO Connect for
                    communication. Stay secure.
                </p>
            </div>
            <div className="flex overflow-x-auto pb-4 md:grid md:grid-cols-3 md:gap-8 md:overflow-x-visible no-scrollbar">
                {platforms.map((platform, index) => (
                    <div key={index} className="flex-shrink-0 w-64 md:w-auto mx-2 md:mx-0">
                        <div className="bg-gray-50 rounded-lg shadow-md overflow-hidden md:h-full flex flex-col">
                            <div c>
                                <img src={platform.image} alt={platform.title} className="w-full h-full object-cover mt-auto block md:hidden" />
                            </div>
                            <div className="p-7 md:p-8">
                                <p className="text-gray-600 font-medium mb-2 text-sm ">{platform.title}</p>
                                <h3 className="text-lg md:text-xl font-semibold mb-2 md:mb-4">{platform.subtitle}</h3>
                                <p className="text-gray-500 mb-4 text-sm md:text-base">{platform.description}</p>
                                <Link to={platform.src} className="text-red-600 font-semibold flex items-center text-sm md:text-base">
                                    Explore Our Platforms
                                    <svg className="ml-2" width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.08125 4.99609L6.90625 6.17109L10.7229 9.99609L6.90625 13.8211L8.08125 14.9961L13.0813 9.99609L8.08125 4.99609Z" fill="#F30002" />
                                    </svg>
                                </Link>
                            </div>
                            <img src={platform.image} alt={platform.title} className="w-full aspect-square  mt-auto hidden md:block" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}