import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
// import ContactForm from './reusable/ContactForm';
// import { useLocation } from 'react-router-dom';


export default function Layout({ children }) {
    // const location = useLocation()

    return (
        <main>
            <Navbar />
            {children}
            {/* {!(location?.pathname === "/terms-of-service" || location?.pathname === "/privacy-policy") && <ContactForm />} */}
            <Footer />
        </main>
    );
}
