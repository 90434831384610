
const SectionHeading = ({ title, subtitle, bg = "bg-gray-50" }) => {
    return (
        <div className={`${bg}`}>
            <div className="text-left md:text-center pt-7 md:pt-14 pb-1 max-w-3xl px-4 md:mx-auto">
                <h2 className="text-red-600 font-semibold mb-2">{title}</h2>
                <h1 className="text-3xl md:text-5xl font-bold mb-4 leading-tight">{subtitle}</h1>
            </div>
        </div>
    )
}

export default SectionHeading