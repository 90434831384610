import React, { useEffect } from 'react'
import certificate from "../assets/certificate.png"
import IdentityAssuranceImg from '../assets/identity-assurance.png'
import SectionWithImage from '../components/reusable/SectionWithImage'

// images
import zeroKnowledgeBiometrics from "../assets/zero-knowledge-biometrics.png"
import backgroundVerification from "../assets/background-verification.png"
import privateComputation from "../assets/private-computation.png"
import antiSpoofing from "../assets/Anti-Spoofing.png"
import adaptiveRisk from "../assets/adaptive-risk-based-authentication-img.png"
import SectionHeading from '../components/reusable/SectionHeading'
import WhyChooseUs from '../components/reusable/WhyChooseUs'
import digitalIdentityImg from "../assets/digital-identity.png"

// icons
import increase from "../assets/increase.svg"
import otp from "../assets/otp.svg"
import blockUser from "../assets/block-user.svg"
import alert from "../assets/alert.svg"
import analysis from "../assets/analysis.svg"
import NewHero from '../components/reusable/NewHero'
import ContactForm from '../components/reusable/ContactForm'

const benefitsData = {
    layout: 'layout2',
    mainHeading: 'Benefits of Choosing Us',
    benefits: [
        {
            icon: increase,
            title: 'Enhanced Security',
            description: 'Achieve the highest level of identity assurance without sacrificing user privacy, leveraging advanced biometric technologies.'
        },
        {
            icon: otp,
            title: 'Seamless Authentication',
            description: 'Background device verification ensures seamless multi-factor authentication, enhancing user experience while maintaining security.'
        },
        {
            icon: blockUser,
            title: 'Anti-Spoofing Protection',
            description: 'Prevent unauthorized access attempts with anti-spoofing and liveness detection technology, ensuring only genuine users can gain access.'
        },
        {
            icon: alert,
            title: 'Real-Time Monitoring and Alerts',
            description: 'Easily scalable to accommodate a growing number of users and devices without compromising on security or performance.'
        },
        {
            icon: analysis,
            title: 'User Convenience',
            description: 'Receive instant notifications and detailed insights through real-time monitoring and alerts, enabling rapid response to potential security threats and ensuring continuous protection of your assets.'
        },
    ]
}

const IdentityAssurance = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <NewHero
                backgroundImage={IdentityAssuranceImg}
                certificationImage={certificate}
                buttonLink="/#products"
                buttonText="Explore Our Solutions"
                certificationText="Tested and Endorsed by Global Cyber Security Experts"
                description="Ensure only authorized users access systems with our real-time Identity Assurance, safeguarding critical assets."
                certificationBadge={certificate}
                title="Seamless, Secure Authentication"
                subtitle="Identity Assurance"
                contentDirection='left'
            />
            <div className='bg-gray-50'>
                <SectionHeading title="Key Features" subtitle="Strengthen Identity Assurance for Total Access Security" />
                <SectionWithImage
                    imageSrc={zeroKnowledgeBiometrics}
                    imageAlt="Zero-Knowledge Biometrics"
                    title="Zero-Knowledge Biometrics"
                    imagePosition="left"
                    buttonText="Get Started"
                    description="Utilize cutting-edge biometric technology combined with zero-knowledge proofs to achieve the highest level of identity assurance without sacrificing user privacy. Our solution ensures that sensitive biometric data remains secure and private, even during authentication processes."
                    buttonLink="#contact"
                />
            </div>
            <SectionWithImage
                imageSrc={backgroundVerification}
                imageAlt="Background Device Verification"
                title="Background Device Verification"
                imagePosition="right"
                buttonText="Get Started"
                description="Seamlessly verify device integrity in the background to enhance multi-factor authentication (MFA). This unobtrusive process adds an extra layer of security without disrupting the user experience, ensuring that only trusted devices can access your systems."
                buttonLink="#contact"
            />
            <SectionWithImage
                imageSrc={privateComputation}
                imageAlt="Secure Private Computation"
                title="Secure Private Computation"
                imagePosition="left"
                buttonText="Get Started"
                description="Employ privacy-enhancing multi-party computation techniques to process authentication data securely. This advanced approach protects personal information during transmission and verification, maintaining user trust and compliance with data protection regulations."
                bgClass="bg-gray-50"
                buttonLink="#contact"
            />
            <SectionWithImage
                imageSrc={antiSpoofing}
                imageAlt="Anti-Spoofing and Liveness Detection"
                title="Anti-Spoofing and Liveness Detection"
                imagePosition="right"
                buttonText="Get Started"
                description="Our technology ensures only real, live users access your system by detecting spoofing attempts, such as photos or videos, adding an extra layer of security to identity verification."
                buttonLink="#contact"
            />
            <SectionWithImage
                imageSrc={adaptiveRisk}
                imageAlt="Adaptive Risk-Based Authentication"
                title="Adaptive Risk-Based Authentication"
                imagePosition="left"
                buttonText="Get Started"
                description="Implement an adaptive authentication mechanism that evaluates various risk factors in real-time. Our patented chaos-based protection adjusts security protocols based on detected risks, providing robust security while maintaining a seamless user experience."
                bgClass="bg-gray-50"
                buttonLink="#contact"
            />
            <WhyChooseUs {...benefitsData} />
            <SectionWithImage
                imageSrc={digitalIdentityImg}
                imageAlt="Secure Your Digital Identity Today"
                title="Secure Your Digital Identity Today"
                imagePosition="right"
                buttonText="Contact Us"
                description="Secure your digital identities and ensure only authorized access. Start protecting your organization today with scalable and compliant solutions."
                bgClass="bg-gray-50"
                buttonLink="#contact"
            />
            <ContactForm />
        </div>
    )
}

export default IdentityAssurance