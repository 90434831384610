import expertTeam from "../assets/Expert-Team.svg"
import innovation from "../assets/innovation.svg"
import review from "../assets/review.svg"
import certified from "../assets/certified.svg"


const features = [
    {
        icon: expertTeam,
        title: "Expert Team",
        description: "Our cybersecurity experts bring years of experience across industries, ensuring your organization is secured by top professionals with proven results."
    },
    {
        icon: innovation,
        title: "Innovative Technology",
        description: "We leverage cutting-edge technology to deliver advanced solutions, from real-time threat detection to automation, keeping your systems safe and secure."
    },
    {
        icon: review,
        title: "Customer-Centric Approach",
        description: "We offer personalized solutions tailored to your specific needs, ensuring seamless integration and ongoing support for lasting protection."
    },
    {
        icon: certified,
        title: "Industry Recognition",
        description: "Our reputation is backed by certifications and awards, recognized for innovative, reliable security solutions in an evolving cyber landscape."
    }
]

export default function TrustBuildingSection() {
    return (
        <section className="py-12 md:py-16 px-4 md:px-6 lg:px-8 bg-white">
            <div className="">
                <h2 className="text-red-600 font-semibold mb-2">Why Choose Us</h2>
                <h3 className="text-3xl md:text-4xl font-bold mb-8 max-w-3xl">
                    To Building Trust Through Expertise, Innovation, and Customer Focus
                </h3>

                <div className="overflow-x-auto pt-8 pb-2 -mx-4 px-4 no-scrollbar">
                    <div className="flex space-x-6 ">
                        {/* w-[calc(4*280px+3*1.5rem)] */}
                        {features.map((feature, index) => (
                            <div key={index} className="w-64 md:w-[380px] flex-shrink-0 bg-[#F2F2F2] rounded-lg  overflow-hidden">
                                <div className="p-6">
                                    <img src={feature.icon} alt="feature inco" className="w-16 h-16 text-red-600 mb-4" />
                                    <h4 className="font-semibold text-lg mb-2">{feature.title}</h4>
                                    <p className="text-gray-600 text-sm">{feature.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}