
import facebook from "../assets/Facebook.svg"
import instagram from "../assets/instagram.svg"
import x from "../assets/X.svg"
import linkedIn from "../assets/linkedIn.svg"
// import youtube from "../assets/youtube.svg"
import { Link } from "react-router-dom";
import SPIOLogo from "../assets/spio-logo.svg"

const navigate = {
    products: [

        { name: 'EDR', href: '/product/edr' },
        { name: 'OT Security', href: '/product/ot-security' },
        { name: 'PAM', href: '/product/pam' },
        { name: 'Identity Assurance', href: '/product/identity-assurance' }

    ],
    platforms: [
        { name: 'SPIOFI', href: '/platforms/spiofi' },
        { name: 'Social Up', href: '/platforms/social-up' },
        { name: 'AI Telephonic Voice and Chat', href: '/platforms/ai-telephonic-voice-and-chat' },
    ],
    services: [
        { name: 'Cyber Security Consulting', href: '/services/cyber-security-consulting' }
    ]
}


const Footer = () => {
    return (
        <footer className="bg-gray-50 py-12 px-4 md:px-8 overflow-hidden mx-auto">
            <div className=" ">
                <div className="flex flex-col lg:flex-row justify-between  md:gap-x-20">
                    {/* Logo and Address Section */}
                    <div className="w-full md:w-full lg:max-w-xs ">
                        <img
                            src={SPIOLogo}
                            alt="SPIO Logo"
                            className="mb-6 w-24 md:w-auto"
                        />
                        <div className="space-y-6">
                            <div>
                                <h3 className="text-lg font-semibold mb-2">Address:</h3>
                                <div className="space-y-4">
                                    <div>
                                        <p className="font-semibold">Hyderabad, India</p>
                                        <p className="text-sm md:text-base text-gray-600">
                                            {/* Smartworks, Raheja Mindspace, HUDA Techno Enclave, HITEC City, Telangana, 500081 */}
                                            102, hemalatha apts, Gagan Mahal Road, Domalguda, Hyderabad, Telangana, 500029
                                        </p>
                                    </div>
                                    <div>
                                        <p className="font-semibold">Bengaluru, India</p>
                                        <p className="text-sm md:text-base text-gray-600">
                                            {/* 4th floor, DSR Techno Cube, Smartworks, Kundalahalli Gate, 560037 */}
                                            8th Floor, Tower E, Helios Business Park, Kadabeesanahalli, Bengaluru, Karnataka, 560103
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="pb-8">
                                <h3 className="text-lg font-semibold mb-2">Contact:</h3>
                                <div className="">
                                    {/* <div className="flex flex-wrap flex-col md:flex-row gap-3 md:gap-x-4"> */}
                                    {/* <a href="tel:+919618844333" className="text-sm text-gray-600 whitespace-nowrap">+91 9618844333</a> */}
                                    <Link to="mailto:support@spiolabs.com" className="text-base text-gray-600 hover:text-gray-900">
                                        support@spiolabs.com
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Navigation Links */}
                    <div className="flex flex-col justify-between w-full">
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 whitespace-nowrap">
                            {/* Products */}
                            <div>
                                <h3 className="text-lg font-semibold mb-4">Products</h3>
                                <ul className="space-y-3 text-sm">
                                    {navigate.products.map((nav, i) => (
                                        <li>
                                            <Link className="text-gray-600 hover:text-gray-900 transition " to={nav.href}>{nav.name} </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Platforms */}
                            <div>
                                <h3 className="text-lg font-semibold mb-4">Platforms</h3>
                                <ul className="space-y-3 text-sm">
                                    {navigate.platforms.map((nav, i) => (
                                        <li>
                                            <Link className="text-gray-600 hover:text-gray-900 transition " to={nav.href}>{nav.name} </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Services */}
                            <div>
                                <h3 className="text-lg font-semibold mb-4">Services</h3>
                                <ul className="space-y-3 text-sm">
                                    {navigate.services.map((nav, i) => (
                                        <li>
                                            <Link className="text-gray-600 hover:text-gray-900 transition " to={nav.href}>{nav.name} </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {/* Social Media Icons */}
                        <div className="flex justify-center md:justify-start space-x-6 my-8">
                            <Link to="https://www.facebook.com/profile.php?id=100085651650007" target="_blank" className="text-gray-600 hover:text-gray-900">
                                <img src={facebook} alt="facebook icon" className="h-6 w-6 md:h-8 md:w-8" />
                                <span className="sr-only">Facebook</span>
                            </Link>
                            <Link to="https://www.instagram.com/spiolabs/" target="_blank" className="text-gray-600 hover:text-gray-900">
                                <img src={instagram} alt="instagram icon" className="h-6 w-6 md:h-8 md:w-8" />
                                <span className="sr-only">Instagram</span>
                            </Link>
                            <Link to="https://x.com/spiolabs?t=-vuC3M7WzhF0gj1yzlhRXw&s=09" target="_blank" className="text-gray-600 hover:text-gray-900">
                                <img src={x} alt="twitter icon" className="h-6 w-6 md:h-8 md:w-8" />
                                <span className="sr-only">Twitter</span>
                            </Link>
                            <Link to="https://www.linkedin.com/company/spiolabs/" target="_blank" className="text-gray-600 hover:text-gray-900">
                                <img src={linkedIn} alt="linkedIn icon" className="h-6 w-6 md:h-8 md:w-8" />
                                <span className="sr-only">LinkedIn</span>
                            </Link>
                            {/* <Link to="/" target="_blank" className="text-gray-600 hover:text-gray-900">
                                <img src={youtube} alt="Youtube icon" className="h-6 w-6 md:h-8 md:w-8" />
                                <span className="sr-only">YouTube</span>
                            </Link> */}
                        </div>
                    </div>
                </div>

                {/* Bottom Bar */}
                <div className="pt-8 border-t border-gray-200">
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                        <p className="text-sm text-gray-600">© 2023 SPIO. All rights reserved.</p>
                        <div className="flex space-x-6">
                            <Link to="/privacy-policy" className="text-sm text-gray-600 hover:text-gray-900 underline">
                                Privacy Policy
                            </Link>
                            <Link to="/terms-of-service" className="text-sm text-gray-600 hover:text-gray-900 underline">
                                Terms of Service
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer



// const Footer = () => {
//     return (
//         <footer className="bg-gray-50 pt-16 pb-8 px-4 md:px-8 overflow-hidden mx-auto">
//             <div className="mx-auto w-11/12">
//                 <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
//                     <div>
//                         <div className="flex justify-center sm:justify-start">
//                             <img
//                                 src={SPIOLogo}
//                                 alt="SPIO Logo"
//                                 className="mb-6 w-24 md:w-auto"
//                             />
//                         </div>

//                         <div className="space-y-6">
//                             <div>
//                                 <h3 className="text-lg font-semibold mb-2">Address:</h3>
//                                 <div className="space-y-4">
//                                     <div>
//                                         <p className="font-semibold">Hyderabad, India</p>
//                                         <p className="text-sm md:text-base text-gray-600">
//                                             Smartworks, Raheja Mindspace, HUDA Techno Enclave, HITEC City, Telangana 500081
//                                         </p>
//                                     </div>
//                                     <div>
//                                         <p className="font-semibold">Bengaluru, India</p>
//                                         <p className="text-sm md:text-base text-gray-600">
//                                             4th floor, DSR Techno Cube, Smartworks, Kundalahalli Gate, 560037
//                                         </p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="pb-8">
//                                 <h3 className="text-lg font-semibold mb-2">Contact:</h3>
//                                 <div className="flex flex-wrap flex-col md:flex-row gap-3 md:gap-x-4">
//                                     <p className="text-sm text-gray-600">+91XXXXXXXXXX</p>
//                                     <a href="mailto:support@spiolabs.com" className="text-sm text-gray-600 hover:text-gray-900">
//                                         support@spiolabs.com
//                                     </a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="grid grid-cols-1 gap-y-20 gap-x-8 sm:grid-cols-3 md:grid-cols-3 lg:col-span-2">
//                         <div className="text-center sm:text-left">
//                             <p className="text-lg font-medium text-gray-900">Products</p>

// <ul className="mt-8 space-y-4 text-sm">
//     {navigate.products.map((nav, i) => (
//         <li>
//             <a className="text-gray-700 transition " href={nav.href}>{nav.name} </a>
//         </li>
//     ))}

// </ul>
//                         </div>

//                         <div className="text-center sm:text-left">
//                             <p className="text-lg font-medium text-gray-900">Platforms</p>

//                             <ul className="mt-8 space-y-4 text-sm">
//                                 {navigate.platforms.map((nav, i) => (
//                                     <li>
//                                         <a className="text-gray-700 transition " href={nav.href}>{nav.name} </a>
//                                     </li>
//                                 ))}

//                             </ul>
//                         </div>

//                         <div className="text-center sm:text-left">
//                             <p className="text-lg font-medium text-gray-900">Services</p>

//                             <ul className="mt-8 space-y-4 text-sm">
//                                 {navigate.services.map((nav, i) => (
//                                     <li>
//                                         <a className="text-gray-700 transition " href={nav.href}>{nav.name} </a>
//                                     </li>
//                                 ))}

//                             </ul>
//                         </div>
//                         <div className="flex justify-center md:justify-start space-x-6 w-full pb-6 md:pb-0">
//                             <Link to="/" className="text-gray-600 hover:text-gray-900">
//                                 <img src={facebook} alt="facebook icon" className="h-6 w-6 md:h-8 md:w-8" />
//                                 <span className="sr-only">Facebook</span>
//                             </Link>
//                             <Link to="/" className="text-gray-600 hover:text-gray-900">
//                                 <img src={instagram} alt="instagram icon" className="h-6 w-6 md:h-8 md:w-8" />
//                                 <span className="sr-only">Instagram</span>
//                             </Link>
//                             <Link to="/" className="text-gray-600 hover:text-gray-900">
//                                 <img src={x} alt="twitter icon" className="h-6 w-6 md:h-8 md:w-8" />
//                                 <span className="sr-only">Twitter</span>
//                             </Link>
//                             <Link to="/" className="text-gray-600 hover:text-gray-900">
//                                 <img src={linkedIn} alt="linkedIn icon" className="h-6 w-6 md:h-8 md:w-8" />
//                                 <span className="sr-only">LinkedIn</span>
//                             </Link>
//                             <Link to="/" className="text-gray-600 hover:text-gray-900">
//                                 <img src={youtube} alt="Youtube icon" className="h-6 w-6 md:h-8 md:w-8" />
//                                 <span className="sr-only">YouTube</span>
//                             </Link>
//                         </div>
//                     </div>




//                 </div>


//                 <div className="pt-8 border-t border-gray-200">
//                     <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
//                         <p className="text-sm text-gray-600">© 2023 SPIO. All rights reserved.</p>
//                         <div className="flex space-x-6">
//                             <Link to="/privacy-policy" className="text-sm text-gray-600 hover:text-gray-900">
//                                 Privacy Policy
//                             </Link>
//                             <Link to="/terms-of-service" className="text-sm text-gray-600 hover:text-gray-900">
//                                 Terms of Service
//                             </Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </footer>
//     )
// }

// export default Footer