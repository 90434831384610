import certificate from "../assets/certificate.png"
import socialUpHero from '../assets/social-up-hero.png'
import SectionWithImage from '../components/reusable/SectionWithImage'
import SectionHeading from '../components/reusable/SectionHeading'
import WhyChooseUs from '../components/reusable/WhyChooseUs'

// images
import realTimeReviewMonitoringImg from "../assets/real-time-review-monitoring.png"
import seamlessReputationManagementImg from "../assets/seamless-reputation-management.png"
import maximizeSocialMediaImg from "../assets/maximize-your-social-media-impact.png"
import gainInsightsImg from "../assets/gain-insights-into-competitors-reputation.png"
import makeDataDrivenDecisionsImg from "../assets/make-data-driven-decisions.png"
import socialUpGetStartedImg from "../assets/socialup-get-started.png"

// icons
import handshake from "../assets/handshake.svg"
import webVisibility from "../assets/web-visibility.svg"
import earning from "../assets/earning.svg"
import userEngagement from "../assets/user-engagement.svg"
import { useEffect } from 'react'
import NewHero from '../components/reusable/NewHero'
import ContactForm from "../components/reusable/ContactForm"

const socialUpData = [
    {
        title: "Real-Time Review Monitoring",
        description: "Stay informed with real-time alerts for every new review your business receives. Social Up ensures you never miss an opportunity to engage with feedback and enhance your customer relationships. By staying ahead of reviews, you can take control of your brand’s reputation and respond to customers swiftly.",
        imageSrc: realTimeReviewMonitoringImg,
        imageAlt: "Real-Time Review Monitoring",
        imagePosition: "left",
        buttonText: "Get Started",
    },
    {
        title: "Seamless Reputation Management",
        description: "Manage and respond to reviews effortlessly from a single dashboard. Social Up makes it easy to track and engage with customer feedback across all platforms. Streamline your reputation management and ensure that your brand is always presented in the best light.",
        imageSrc: seamlessReputationManagementImg,
        imageAlt: "Seamless Reputation Management",
        imagePosition: "right",
        buttonText: "Get Started",
    },
    {
        title: "Maximize Your Social Media Impact",
        description: "Take control of your social media strategy with advanced tools to schedule posts, analyze engagement, and interact with your followers. Social Up keeps your brand active and engaging across all platforms, helping you build a strong and consistent social presence that resonates with your audience.",
        imageSrc: maximizeSocialMediaImg,
        imageAlt: "Maximize Your Social Media Impact",
        imagePosition: "left",
        buttonText: "Get Started",
    },
    {
        title: "Gain Insights into Competitors' Reputation",
        description: "Stay ahead by tracking your competitors' online reputation. Social Up’s competitive analysis tools provide insights into what works for others, helping you refine your strategy. Understand your competitors’ strengths and weaknesses, and use this knowledge to outperform them and enhance your brand’s performance.",
        imageSrc: gainInsightsImg,
        imageAlt: "Gain Insights into Competitors' Reputation",
        imagePosition: "right",
        buttonText: "Get Started",
    },
    {
        title: "Make Data-Driven Decisions with Real-Time Insights",
        description: "Gain a deeper understanding of your audience with real-time insights into how they interact with your brand. Track performance, adjust your strategies, and ensure that your efforts are always aligned with your business goals. Social Up empowers you with the data you need to make informed decisions and continuously improve your brand’s performance.",
        imageSrc: makeDataDrivenDecisionsImg,
        imageAlt: "Make Data-Driven Decisions with Real-Time Insights",
        imagePosition: "left",
        buttonText: "Get Started",
    },

]
const benefitsData = {
    layout: 'layout2',
    mainHeading: 'Benefits of Choosing Us',
    benefits: [
        {
            icon: handshake,
            title: 'Build Trust and Loyalty',
            description: 'Engage with customers and showcase positive reviews to build trust and foster loyalty, driving repeat business and referrals.'
        },
        {
            icon: webVisibility,
            title: 'Increase Visibility',
            description: 'Optimize your social media presence to reach a broader audience, driving more traffic and engagement with targeted strategies.'
        },
        {
            icon: earning,
            title: 'Drive Revenue',
            description: 'Automate tasks, manage accounts easily, and focus on growing your business—turn customer engagement into increased revenue.'
        },
        {
            icon: userEngagement,
            title: 'Enhance Customer Engagement',
            description: 'Engage in real-time with customers, creating meaningful connections that increase conversions and strengthen relationships.'
        },
    ]
}


const SocialUp = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <NewHero
                backgroundImage={socialUpHero}
                buttonLink="/#products"
                buttonText="Explore Our Solutions"
                certificationText="Tested and Endorsed by Global Cyber Security Experts"
                description="Automate reviews, manage reputation, and engage audiences—all from one dashboard."
                certificationBadge={certificate}
                title="Elevate your social presence effortlessly"
                subtitle="Social Up"
                contentDirection='left'
            />
            <div className=''>
                <SectionHeading subtitle="Complete Tools to Manage Reputation and Social Media in One Place" title="Key Features" />
                {socialUpData.map((data, index) => (
                    <SectionWithImage
                        key={index}
                        imageSrc={data.imageSrc}
                        imageAlt={data.title}
                        title={data.title}
                        imagePosition={data.imagePosition}
                        buttonText={data.buttonText}
                        description={data.description}
                        bgClass={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                        buttonLink="#contact"
                    />
                ))}
            </div>
            <WhyChooseUs
                layout={benefitsData.layout}
                mainHeading="Benefits of Choosing Us"
                benefits={benefitsData.benefits}
                columns={4}
            />
            <SectionWithImage
                imageSrc={socialUpGetStartedImg}
                imageAlt="Get Started Today"
                title="Get Started Today"
                imagePosition="right"
                buttonText="Contact Us"
                description="Take control of your brand’s reputation with Social Up. Manage reviews, engage with customers, and optimize social media from one easy platform."
                bgClass="bg-gray-50"
                buttonLink="#contact"
            />
            <ContactForm />
        </div>
    )
}

export default SocialUp