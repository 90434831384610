import Button from "./Button";

const bgStyles = {
    bgBlack: 'bg-black text-white',
    bgWhite: 'bg-gray-50 text-black',
};

export default function Hero({
    direction,
    title,
    subtitle,
    description,
    buttonText,
    buttonLink,
    certificationText,
    certificationLogo,
    heroImg,
    heroAlt,
    bgColor = "bgBlack"
}) {
    return (
        <section className={`${bgStyles[bgColor]} flex items-center h-[90vh] md:h-full pt-16`}>
            <div className=" mx-auto px-4 sm:px-6 md:px-8 py-6">
                <div className={`flex flex-col ${direction === 'left' ? 'md:flex-row' : 'md:flex-row-reverse'} items-center gap-8 md:gap-4`}>
                    <div className="w-full md:w-1/2 space-y-6">
                        <h2 className={`text-sm font-semibold ${bgColor === "bgBlack" ? "text-red-500" : "text-black"}`}>{subtitle}</h2>
                        <h1 className="text-2xl md:text-5xl font-bold leading-tight">{title}</h1>
                        <p className={`text-lg sm:text-xl lg:text-lg xl:text-xl ${bgColor === "bgBlack" ? "text-gray-300" : "text-black"}`}>{description}</p>
                        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-6">
                            {/* <a href={buttonLink}> */}
                            <Button href={buttonLink}>
                                {buttonText}
                            </Button>
                            {/* </a> */}
                            {certificationText && <p className="text-lg text-gray-400 block md:hidden">{certificationText}</p>}
                            <img
                                src={certificationLogo}
                                alt="Certification Logo"
                                width={60}
                                height={60}
                                className="rounded-full"
                            />
                            {certificationText && <p className="text-lg text-gray-400 hidden md:block max-w-xs">{certificationText}</p>}
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 hidden md:grid place-items-end">
                        <div className="relative">
                            <img
                                src={heroImg}
                                alt={heroAlt}
                                className="object-fill"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
