import certificate from "../assets/certificate.png"
import spiofiImg from '../assets/spiofi-hero-image.png'
import SectionWithImage from '../components/reusable/SectionWithImage'
import simplifyWifiImg from "../assets/simplify-wi-fi.png"

// images
import vendorAgnostic from "../assets/vendor-agnostic-compatibility.png"
import centralizedDashboard from "../assets/centralized-dashboard.png"
import userPolicy from "../assets/user-policy-framework.png"
import profileBsedBandwidth from "../assets/profile-based-bandwidth-img.png"
import singleSSIDImg from "../assets/single-ssid.png"
import groupBasedUserMappingImg from "../assets/group-based-user-mapping.png"
import SectionHeading from '../components/reusable/SectionHeading'
import WhyChooseUs from '../components/reusable/WhyChooseUs'

// icons
import plugPlay from "../assets/plug-and-play.svg"
import password from "../assets/password.svg"
import preIntegratedGateways from "../assets/pre-integrated-gateways.svg"
import alert from "../assets/alert.svg"
import increase from "../assets/increase.svg"
import { useEffect } from 'react'
import NewHero from '../components/reusable/NewHero'
import ContactForm from "../components/reusable/ContactForm"

const spiofiData = [
    {
        title: "Vendor-Agnostic Compatibility",
        description: "Manage user access authentication across any combination of hardware and access points. SPIOFI is vendor-agnostic, allowing seamless integration with your existing infrastructure.",
        imageSrc: vendorAgnostic,
        imageAlt: "Vendor-Agnostic Compatibility",
        imagePosition: "left",
        buttonText: "Get Started",
    },
    {
        title: "Centralized Dashboard",
        description: "Control your entire network from a single, intuitive dashboard. Manage bandwidth for guests, employees, VIPs, and others—all in one place.",
        imageSrc: centralizedDashboard,
        imageAlt: "Centralized Dashboard",
        imagePosition: "right",
        buttonText: "Get Started",
    },
    {
        title: "User Policy Framework",
        description: "Customize user experiences with granular control over speed, data limits, time restrictions, and browsing permissions. Implement policies that suit different user groups effortlessly.",
        imageSrc: userPolicy,
        imageAlt: "User Policy Framework",
        imagePosition: "right",
        buttonText: "Get Started",
    },
    {
        title: "User Profile-Based Bandwidth Allocation",
        description: "Assign bandwidth based on user profiles. Whether managing multiple units or groups, our platform makes it easy to allocate resources efficiently.",
        imageSrc: profileBsedBandwidth,
        imageAlt: "User Profile-Based Bandwidth Allocation",
        imagePosition: "left",
        buttonText: "Get Started",
    },
    {
        title: "Single SSID for All Users",
        description: "Eliminate network clutter with a single SSID for everyone. Simplify the connection process while maintaining control over access levels and permissions.",
        imageSrc: singleSSIDImg,
        imageAlt: "Single SSID for All Users",
        imagePosition: "left",
        buttonText: "Get Started",
    },
    {
        title: "Group-Based User Mapping",
        description: "Auto-assign access policies to pre-configured groups. Streamline user management by mapping users to specific groups with predefined settings.",
        imageSrc: groupBasedUserMappingImg,
        imageAlt: "Group-Based User Mapping",
        imagePosition: "right",
        buttonText: "Get Started",
    },
]
const benefitsData = {
    layout: 'layout2',
    mainHeading: 'Benefits of Choosing Us',
    benefits: [
        {
            icon: plugPlay,
            title: 'Plug and Play Setup',
            description: 'Achieve complete end-to-end setup in just a matter of hours. Our plug-and-play approach allows for quick deployment and minimal downtime, so you can get back to business swiftly.'
        },
        {
            icon: password,
            title: 'Multiple User Authentication Modes',
            description: 'Onboard users in the fastest possible way via QR code, OTP (One-Time Password), and email. Provide a self-service portal for registration and access requests, making the process fast and user-friendly.'
        },
        {
            icon: preIntegratedGateways,
            title: 'Pre-Integrated Gateways',
            description: 'Includes built-in SMS and email gateways for efficient and streamlined communication with users. Simplify user authentication and notifications without the need for additional integrations.'
        },
        {
            icon: alert,
            title: 'Real-Time Monitoring and Alerts',
            description: 'Receive instant notifications and detailed insights through real-time monitoring and alerts. Enable rapid response to potential security threats and ensure continuous protection of your Wi-Fi network.'
        },
        {
            icon: increase,
            title: 'Enhanced Security Measures',
            description: 'Implement advanced security protocols such as WPA3 encryption and regular security audits. Protect your network from unauthorized access and cyber threats with robust, up-to-date security features.'
        },
    ]
}

const Spiofi = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <NewHero
                backgroundImage={spiofiImg}
                buttonLink="#contact"
                buttonText="Talk to an Expert"
                certificationText="Tested and Endorsed by Global Cyber Security Experts"
                description="Navigate the future of connectivity with SPIOFI. Our platform simplifies Wi-Fi management while boosting security and insights."
                certificationBadge={certificate}
                title="Future Ready Wi-Fi Management"
                subtitle="SPIOFI"
                contentDirection='left'
            />
            <div className=''>
                <SectionHeading subtitle="Strengthen Identity Assurance for Total Access Security" title="Key Features" />
                {spiofiData.map((data, index) => (
                    <SectionWithImage
                        key={index}
                        imageSrc={data.imageSrc}
                        imageAlt={data.title}
                        title={data.title}
                        imagePosition={data.imagePosition}
                        buttonText={data.buttonText}
                        description={data.description}
                        bgClass={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                        buttonLink="#contact"
                    />
                ))}
            </div>
            <WhyChooseUs {...benefitsData} />
            <SectionWithImage
                imageSrc={simplifyWifiImg}
                imageAlt="Simplify Wi-Fi Access"
                title="Simplify Wi-Fi Access"
                imagePosition="right"
                buttonText="Contact Us"
                description="Unify and streamline your Wi-Fi access across all devices. Enhance security and improve user experience with SPIOFI. Get started in minutes."
                bgClass="bg-gray-50"
                buttonLink="#contact"
            />
            <ContactForm />
        </div>
    )
}

export default Spiofi