import Button from "../reusable/Button";

export default function SectionWithImage({
    imageSrc,
    imageAlt,
    imagePosition,
    title,
    subtitle,
    description,
    buttonText,
    buttonLink,
    bgClass,
    id
}) {
    return (
        <div id={`${id}`} className={`${bgClass} scroll-mt-16`}>
            <div className={` mx-auto px-6 md:px-8 py-10 md:py-14 `}>
                <div className={`flex flex-col md:flex-row items-center gap-8 ${imagePosition === "right" ? "md:flex-row-reverse" : ""}`}>
                    <div className="w-full md:w-1/2">
                        <img
                            src={imageSrc}
                            alt={imageAlt}
                            className="rounded-lg object-cover md:object-contain w-full h-full md:h-[550px] object-center aspect-square"
                        />
                    </div>
                    <div className="w-full md:w-1/2 space-y-4 max-w-3xl">
                        {subtitle && (
                            <p className="text-red-600 font-semibold">{subtitle}</p>
                        )}
                        {title && <h2 className="text-3xl md:text-5xl font-bold leading-tight">
                            {title}
                        </h2>}
                        {description && <p className="text-gray-600 text-base pb-7 md:pb-8 ">
                            {description}
                        </p>}
                        {buttonText && <a href={buttonLink}><Button>
                            {buttonText}
                        </Button>
                        </a>}
                    </div>
                </div>
            </div>
        </div>
    );
}
