import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Layout from './components/Layout';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './error-page';
import PreventCyberAttacks from './pages/prevent-cyber-attacks-in-real-time';
import PAM from './pages/privilege-access-management';
import OtSecurity from './pages/ot-security';
import Spiofi from './pages/spiofi';
import SocialUp from './pages/social-up';
import AITelephonic from './pages/ai-telephonic-voice-and-chat';
import CyberSecurityConsulting from './pages/cyber-security-consulting';
import IdentityAssurance from './pages/identity-assurance';
import PrivacyPolicy from './pages/privacy-policy';
import TermsOfService from './pages/terms-of-service';

const router = createBrowserRouter([
  {
    path: "/",
    element:
      <Layout>
        <App />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "/product/edr",
    element:
      <Layout>
        <PreventCyberAttacks />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "/product/pam",
    element:
      <Layout>
        <PAM />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "/product/ot-security",
    element:
      <Layout>
        <OtSecurity />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "/product/identity-assurance",
    element:
      <Layout>
        <IdentityAssurance />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "/platforms/spiofi",
    element:
      <Layout>
        <Spiofi />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "/platforms/social-up",
    element:
      <Layout>
        <SocialUp />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "/platforms/ai-telephonic-voice-and-chat",
    element:
      <Layout>
        <AITelephonic />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "/services/cyber-security-consulting",
    element:
      <Layout>
        <CyberSecurityConsulting />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "/privacy-policy",
    element:
      <Layout>
        <PrivacyPolicy />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "/terms-of-service",
    element:
      <Layout>
        <TermsOfService />
      </Layout>,
    errorElement:
      <Layout>
        <ErrorPage />
      </Layout>
  },
  {
    path: "*",
    element:
      <Layout>
        <ErrorPage />
      </Layout>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
