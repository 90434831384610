import SectionWithImage from '../components/reusable/SectionWithImage'
import SectionHeading from '../components/reusable/SectionHeading'
import WhyChooseUs from '../components/reusable/WhyChooseUs'
import Hero from '../components/reusable/Hero'

// images
import certificate from "../assets/certificate.png"
import cyberConsultingHero from '../assets/cyber-security.png'

import OffensiveSecurityImg from "../assets/offensive-security.png"
import defensiveSecurityImg from "../assets/defensive-security.png"
import complianceServicesImg from "../assets/compliance-services.png"
import productSolutionsImg from "../assets/product-solutions.png"

import secureYourFutureImg from "../assets/secure-your-future.png"

// icons
import handshake from "../assets/handshake.svg"
import webVisibility from "../assets/web-visibility.svg"
import earning from "../assets/earning.svg"
import userEngagement from "../assets/user-engagement.svg"
import { useEffect } from 'react'
import ContactForm from '../components/reusable/ContactForm'

const spiofiData = [
    {
        title: "Offensive Security Services",
        description: "Identify vulnerabilities before attackers do. Our offensive security services include penetration testing for network, IoT, cloud, Wi-Fi, mobile, and web applications. We simulate real-world attack scenarios to expose weaknesses and provide actionable insights to fortify your defenses.",
        imageSrc: OffensiveSecurityImg,
        imageAlt: "Offensive Security Services",
        imagePosition: "left",
        buttonText: "Get Started",
    },
    {
        title: "Defensive Security Services",
        description: "Defensive security is about building a resilient infrastructure. We offer SOC as a service, server hardening, and configuration audits to ensure your systems are protected against cyberattacks. Our team continuously monitors your environment to detect threats early and prevent breaches.",
        imageSrc: defensiveSecurityImg,
        imageAlt: "Defensive Security Services",
        imagePosition: "right",
        buttonText: "Get Started",
    },
    {
        title: "Compliance Services",
        description: "Compliance isn’t just about avoiding penalties. it’s about building trust with your customers. Our compliance services help you meet regulatory requirements, enhance business efficiency, and protect your reputation. Stay compliant with evolving standards while improving your overall security posture.",
        imageSrc: complianceServicesImg,
        imageAlt: "Compliance Services",
        imagePosition: "left",
        buttonText: "Get Started",
    },
    {
        title: "Product & Solutions",
        description: "From identity and access management to data loss prevention, our products and solutions provide tailored protection for your organization. Secure your sensitive information and ensure that only the right people have access to it—preventing breaches and minimizing risk.",
        imageSrc: productSolutionsImg,
        imageAlt: "Product & Solutions",
        imagePosition: "right",
        buttonText: "Get Started",
    },
]

const benefitsData = {
    layout: 'layout2',
    mainHeading: 'Benefits of Choosing Us',
    benefits: [
        {
            icon: handshake,
            title: 'Build Trust and Loyalty',
            description: 'Engage with customers and showcase positive reviews to build trust and foster loyalty, driving repeat business and referrals.'
        },
        {
            icon: webVisibility,
            title: 'Increase Visibility',
            description: 'Optimize your social media presence to reach a broader audience, driving more traffic and engagement with targeted strategies.'
        },
        {
            icon: earning,
            title: 'Drive Revenue',
            description: 'Automate tasks, manage accounts easily, and focus on growing your business—turn customer engagement into increased revenue.'
        },
        {
            icon: userEngagement,
            title: 'Enhance Customer Engagement',
            description: 'Engage in real-time with customers, creating meaningful connections that increase conversions and strengthen relationships.'
        },
    ]
}

const CyberSecurityConsulting = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Hero
                direction='left'
                title='Protect Your Business with Expert Guidance'
                subtitle='Cyber Security Consulting'
                description='Our consulting services are designed to safeguard your organization against evolving cyber threats. With tailored strategies and proactive defenses, we help you stay secure and compliant, giving you peace of mind in today’s digital landscape.'
                buttonText='Talk to an Expert'
                certificationText='Tested and Endorsed by Global Cyber Security Experts'
                certificationLogo={certificate}
                heroImg={cyberConsultingHero}
                heroAlt={"Cyber Security Illustration"}
                bgColor='bgWhite'
                buttonLink="#contact"
            />
            <div className=''>
                <SectionHeading subtitle="End-to-End Cyber Security Solutions" title="Key Features" bg='bg-white' />
                {spiofiData.map((data, index) => (
                    <SectionWithImage
                        key={index}
                        imageSrc={data.imageSrc}
                        imageAlt={data.title}
                        title={data.title}
                        imagePosition={data.imagePosition}
                        buttonText={data.buttonText}
                        description={data.description}
                        bgClass={index % 2 !== 0 ? "bg-gray-50" : "bg-white"}
                        buttonLink="#contact"
                    />
                ))}
            </div>
            {/* <WhyChooseUs {...benefitsData} /> */}
            <WhyChooseUs
                layout={benefitsData.layout}
                // title="Why Choose Us"
                mainHeading="Benefits of Choosing Us"
                // description="Here’s why you should choose us"
                benefits={benefitsData.benefits}
                columns={4}
            />
            <SectionWithImage
                imageSrc={secureYourFutureImg}
                imageAlt="Secure Your Future"
                title="Secure Your Future"
                imagePosition="right"
                buttonText="Contact Us"
                description="Protect your organization from evolving threats with expert consulting and proactive defense strategies. Stay secure, compliant, and resilient."
                bgClass="bg-gray-50"
                buttonLink="#contact"
            />
            <ContactForm />
        </div>
    )
}

export default CyberSecurityConsulting