
import SectionWithImage from '../components/reusable/SectionWithImage'
import SectionHeading from '../components/reusable/SectionHeading'
import WhyChooseUs from '../components/reusable/WhyChooseUs'

// images
import certificate from "../assets/certificate.png"
import aiTelephonicHero from '../assets/ai-telephonic-hero.png'

import chatBotImg from "../assets/chat-bot-img.png"
import seamlessIntegrationImg from "../assets/seamless-integration.png"
import availabilityImg from "../assets/availability.png"
import scalableImg from "../assets/scalable-for-any-business.png"

import aiPoweredConversationImg from "../assets/ai-powered-conversations.png"

// icons
import expand from "../assets/expand.svg"
import rating from "../assets/rating.svg"
import reduceCost from "../assets/operational-costs.svg"
import time from "../assets/time.svg"
import { useEffect } from 'react'
import NewHero from '../components/reusable/NewHero'
import ContactForm from '../components/reusable/ContactForm'

const spiofiData = [
    {
        title: "Human-Like Conversations Powered by NLP",
        description: "Our AI uses advanced NLP to understand and respond to customer queries in a natural, conversational manner. Whether over the phone or through chat, it adapts to customer intent, offering fluid and intuitive responses.",
        imageSrc: chatBotImg,
        imageAlt: "Human-Like Conversations Powered by NLP",
        imagePosition: "left",
        buttonText: "Get Started",
    },
    {
        title: "Seamless Integration Across Platforms",
        description: "Our AI solutions work across multiple communication channels, including telephonic systems, live chat, social media, and messaging apps. With one unified solution, you can manage customer conversations effortlessly, ensuring consistent service quality.",
        imageSrc: seamlessIntegrationImg,
        imageAlt: "Seamless Integration Across Platforms",
        imagePosition: "right",
        buttonText: "Get Started",
    },
    {
        title: "24/7 Availability",
        description: "Provide uninterrupted customer service with AI-powered agents available 24/7. Our AI never sleeps, ensuring that your customers can get help whenever they need it, reducing wait times and improving satisfaction.",
        imageSrc: availabilityImg,
        imageAlt: "24/7 Availability",
        imagePosition: "left",
        buttonText: "Get Started",
    },
    {
        title: "Scalable for Any Business",
        description: "Whether you’re a small business or an enterprise, our AI solutions are scalable to meet your needs. As your business grows, our AI adapts to handle more customers and provide personalized service at scale.",
        imageSrc: scalableImg,
        imageAlt: "Scalable for Any Business",
        imagePosition: "right",
        buttonText: "Get Started",
    },
]
const benefitsData = {
    layout: 'layout2',
    mainHeading: 'Benefits of Choosing Us',
    benefits: [
        {
            icon: rating,
            title: 'Improve Customer Satisfaction',
            description: 'Reduce wait times and improve response accuracy with AI-driven customer service. Faster, consistent interactions ensure your customers are always happy and engaged.'
        },
        {
            icon: reduceCost,
            title: 'Reduce Operational Costs',
            description: 'By automating routine tasks and reducing the need for human agents, you can save on labor costs while increasing the efficiency of your support operations.'
        },
        {
            icon: time,
            title: 'Free Up Human Agents',
            description: 'With AI handling the repetitive inquiries, your human agents can focus on solving complex issues, providing higher-value support to your customers.'
        },
        {
            icon: expand,
            title: 'Scale Easily',
            description: 'As your business grows, our AI solutions scale effortlessly to accommodate more customer interactions, without compromising on quality or speed.'
        },
    ]
}


const AITelephonic = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <NewHero
                backgroundImage={aiTelephonicHero}
                buttonLink="#contact"
                buttonText="Talk to an Expert"
                certificationText="Tested and Endorsed by Global Cyber Security Experts"
                description="Transform Customer Interaction with AI-Powered Voice and Chat Solutions."
                certificationBadge={certificate}
                title="Human-Like Conversations, Automated Efficiency"
                subtitle="AI Telephonic Voice and Chat"
                contentDirection='left'
            />
            <div className=''>
                <SectionHeading subtitle="Automate Conversations, Enhance Engagement" title="Key Features" />
                {spiofiData.map((data, index) => (
                    <SectionWithImage
                        key={index}
                        imageSrc={data.imageSrc}
                        imageAlt={data.title}
                        title={data.title}
                        imagePosition={data.imagePosition}
                        buttonText={data.buttonText}
                        description={data.description}
                        bgClass={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                        buttonLink="#contact"
                    />
                ))}
            </div>
            {/* <WhyChooseUs {...benefitsData} /> */}
            <WhyChooseUs
                layout={benefitsData.layout}
                mainHeading="Benefits of Choosing Us"
                benefits={benefitsData.benefits}
                columns={4}
            />
            <SectionWithImage
                imageSrc={aiPoweredConversationImg}
                imageAlt="Get Started with AI-Powered Conversations"
                title="Get Started with AI-Powered Conversations"
                imagePosition="right"
                buttonText="Contact Us"
                description="Deliver seamless, efficient customer interactions with AI-powered voice and chat services. Improve satisfaction and reduce response times effortlessly."
                bgClass="bg-gray-50"
                buttonLink="#contact"
            />
            <ContactForm />
        </div>
    )
}

export default AITelephonic