import SectionWithImage from '../components/reusable/SectionWithImage'
import Hero from '../components/reusable/Hero'
import certificate from "../assets/certificate.png"
import EDRHero from "../assets/EDRHero.png"
import protectImg from '../assets/protect-your-business.png'
import SectionWithImageAndScroll from '../components/reusable/SectionWithImageAndScroll'
import Device from "../assets/Setup.png"
import WhyChooseUs from "../components/reusable/WhyChooseUs"

import preventImg from "../assets/preventionImg.png"
import artBoard from "../assets/Artboard.png"
import defend from "../assets/Defend-Against-Every-Threat.png"

// icon
import realTime from "../assets/Real-Time-Over-Sight.svg"
import IOT from "../assets/IOT-Security.svg"
import safeGuard from "../assets/Safeguarding-Teams.svg"
import revMech from "../assets/Reverse-Tracking-Mechanism.svg"
import zeroTrust from "../assets/Zero-Trust.svg"
import mathChaos from "../assets/mathematical-chaos.svg"
import adaptive from "../assets/Shield.svg"
import downtime from "../assets/down-time.svg"
import compliant from "../assets/compliant.svg"
import peace from "../assets/peace-symbol.svg"
import SectionHeading from '../components/reusable/SectionHeading'
import { useEffect } from 'react'
import ContactForm from '../components/reusable/ContactForm'



const monitoringAndControl = [
    {
        icon: realTime,
        title: "Real-Time Oversight",
        subtitle: "Your Security Dashboard",
        description: "When a security incident happens, every second counts. We offer real-time data recording and analysis, enabling you to act swiftly and decisively."
    },
    {
        icon: IOT,
        title: "IoT Security",
        subtitle: "Covering All Bases",
        description: "IoT devices are often the weakest link in your network. We extend our security measures to cover your IoT devices."
    },
    {
        icon: safeGuard,
        title: "Safe Remote Work",
        subtitle: "In Hybrid Environments",
        description: "Remote work increases cyber risks. Installing our agents blocks malware from infiltrating your network, even if a home system is compromised."
    }
];
const detectionData = [
    {
        icon: revMech,
        title: "Stay Ahead with Reverse ",
        subtitle: "Tracking Mechanism",
        description: "Zero-Day attacks are unpredictable and can be devastating. Our Reverse Tracking Mechanism checks every process for authenticity."
    },
    {
        icon: zeroTrust,
        title: "Zero Trust",
        subtitle: "The New Norm",
        description: "In an age where cyber threats are a constant, the Zero Trust model is your first layer of defense. It's not about skepticism; it's about proactive."
    }
];
const preventionData = [
    {
        icon: mathChaos,
        title: "Security Through Unpredictability",
        subtitle: "with Mathematical chaos",
        description: "Our security uses chaos to scramble rules, constantly changing them to prevent patterns and keep attackers guessing, making unauthorized access impossible."
    },
    {
        icon: adaptive,
        title: "Adaptive Defense is",
        subtitle: "Always One Step Ahead",
        description: "A moving target is harder to hit. Our Moving Target Defense keeps your network's access points in constant flux, turning it into a labyrinth for potential attackers."
    }
];
const benefitsData = {
    layout: 'layout1',
    title: 'Benefits of Choosing Us',
    mainHeading: 'Experience Secure Growth with Us',
    description: 'We help you focus on what matters: growing your business. With comprehensive cybersecurity, you can minimize risks, maximize uptime, and keep sensitive data safe—while staying ahead of industry regulations and building customer trust.',
    benefits: [
        {
            icon: downtime,
            title: 'Minimize Downtime and Maximize Productivity',
            description: 'We detect and address threats instantly to keep your operations running smoothly, preventing costly interruptions and delays.'
        },
        {
            icon: compliant,
            title: 'Protect Sensitive Data and Ensure Compliance',
            description: 'Our solutions secure your critical data from breaches, ensuring regulatory compliance and maintaining the trust of your customers.'
        },
        {
            icon: peace,
            title: 'Enjoy Peace of Mind with Comprehensive Security',
            description: 'Your business stays protected with our proactive defenses, allowing you to focus on growth while we handle the security.'
        }
    ]
}


const PreventCyberAttacks = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Hero
                direction='left'
                title='Prevent Cyber Attacks in Real Time'
                subtitle='EDR'
                description='Our EDR solution delivers powerful defense, detecting and neutralizing cyber threats in real-time to keep your business safe from attack.'
                buttonText='Request Demo Now'
                buttonLink="#contact"
                certificationText='Tested and Endorsed by Global Cyber Security Experts'
                certificationLogo={certificate}
                heroImg={EDRHero}
                heroAlt={"Cyber Security Illustration"}

            />
            <SectionWithImage
                subtitle={"Pain Points We Address"}
                title={"Protect Your Business with Expert Guidance"}
                description={"Cybersecurity threats are evolving rapidly, and businesses face increasing risks from sophisticated cyber attacks and zero-day vulnerabilities. These challenges demand a solution that is both proactive and reliable, ensuring your business stays protected around the clock."}
                buttonText={"Explore Our Service"}
                buttonLink="/#services"
                imageSrc={protectImg}
                imageAlt={"Protect Your Business with Expert Guidance"}
                imagePosition={"left"}

            />
            <div className='bg-gray-50'>
                <SectionHeading title="Key Features" subtitle="Cutting-Edge Technology, Built for Protection" />
                <SectionWithImageAndScroll
                    imageSrc={Device}
                    imageAlt="Business protection"
                    title="Set Up Once, Stay Secured Forever"
                    subtitle='Detection'
                    description="Automate trust verification. Eliminate threats by identifying and blocking them in real-time, all within a single platform."
                    buttonText="Get Started"
                    onButtonClick={() => console.log("Button clicked!")}
                    features={detectionData}
                    imageSize="style3"
                    cardSize="w-64 sm:w-[340px] md:w-1/2"
                    buttonLink="#contact"

                />
            </div>
            <SectionWithImageAndScroll
                imageSrc={preventImg}
                imageAlt="Business protection"
                title="Stay Unpredictable, Stay Secure"
                subtitle='Prevention'
                description="Boost your security posture. Confuse attackers by constantly changing the rules, making unauthorized access a near impossibilit."
                buttonText="Get Started"
                onButtonClick={() => console.log("Button clicked!")}
                features={preventionData}
                imageSize="style3"
                imagePosition="right"
                cardSize="w-64 sm:w-[340px] md:w-1/2"
                buttonLink="#contact"
            />
            <SectionWithImageAndScroll
                imageSrc={artBoard}
                imageAlt="Business protection"
                title="See All, Miss None"
                subtitle='Monitoring & Control'
                description="Achieve unparalleled oversight with real-time analytics and adaptive responses, ensuring that every aspect of your cybersecurity is under control."
                buttonText="Get Started"
                onButtonClick={() => console.log("Button clicked!")}
                features={monitoringAndControl}
                imageSize="style3"
                buttonLink="#contact"
            />

            <WhyChooseUs {...benefitsData} />
            <SectionWithImage
                // subtitle={"Pain Points We Address"}
                title="Defend Against Every Threat"
                description="Stop attacks before they cause damage. Protect your endpoints with real-time detection and rapid response. Secure your network today."
                buttonText="Contact Us"
                imageSrc={defend}
                imageAlt="Defend Against Every Threat"
                imagePosition={"right"}
                bgClass="bg-[#f2f2f2]"
                buttonLink="#contact"
            />
            <ContactForm />
        </div>
    )
}

export default PreventCyberAttacks






