import React, { useEffect } from 'react'
import certificate from "../assets/certificate.png"
import OtSecurityHero from '../assets/ot-security-hero.png'
import SectionWithImage from '../components/reusable/SectionWithImage'

// image
import uniqueScrambling from "../assets/unique-scrambling.png"
import reverseTrackingImg from "../assets/reverse-tracking-mechanism-img.png"
import zeroTrustArchitecture from "../assets/zero-trust-architecture.png"
import scrambledNetwork from "../assets/scrambled-network-control-state.png"
import realTimeMonitoringImg from "../assets/real-Time-monitoring.png"
import secureYourIndustrialSystems from "../assets/secure-your-industrial-systems.png"

// icon
import downtime from "../assets/down-time.svg"
import compliant from "../assets/compliant.svg"
import peace from "../assets/peace-symbol.svg"
import WhyChooseUs from '../components/reusable/WhyChooseUs'
import SectionHeading from '../components/reusable/SectionHeading'
import NewHero from '../components/reusable/NewHero'
import ContactForm from '../components/reusable/ContactForm'
// import { useNavigate } from 'react-router-dom'


const benefitsData = {
    layout: 'layout2',
    // title: 'Benefits of Choosing Us',
    mainHeading: 'Why Choose Our OT Security Solution?',
    // description: 'Our PAM solution does more than just protect your network—it transforms how your business operates. By integrating advanced security features and user-friendly management tools, we help you save time, reduce risk, and stay compliant. With our comprehensive approach to privileged access management, you can focus on what matters most—growing your business.',
    benefits: [
        {
            icon: downtime,
            title: 'Operational Efficiency ',
            description: 'Simplified deployment with minimal system impact.'
        },
        {
            icon: compliant,
            title: 'Real-Time Insights',
            description: 'Monitor your infrastructure with intuitive, real-time tools.'
        },
        {
            icon: peace,
            title: 'Industry Trust',
            description: 'Recognized and certified by leading industry bodies.'
        },

    ]
}


const OtSecurity = () => {
    // const navigate = useNavigate();
    // const goToSection = (sectionId) => {
    //     navigate(`/#${sectionId}`);
    // }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <NewHero
                backgroundImage={OtSecurityHero}
                certificationImage={certificate}
                buttonLink="/#products"
                // buttonLink={() => goToSection("products")}
                buttonText="Explore Our Solutions"
                certificationText="Tested and Endorsed by Global Cyber Security Experts"
                description="Shield controllers, safeguard data, and effectively defend against all cyber threats with our unbeatable OT Security solution"
                certificationBadge={certificate}
                title="Unbreachable Security for Industrial Systems"
                subtitle="OT Security"
                contentDirection='right'
            />
            <div className='bg-gray-50'>
                <SectionHeading title="Key Features" subtitle="Innovative Technologies for Unbreakable Defense" />
                <SectionWithImage
                    imageSrc={uniqueScrambling}
                    imageAlt="Secure Privileged Access Today"
                    subtitle="Unique Scrambling Mechanism"
                    title="Scramble. Secure. Stop Threats"
                    imagePosition="left"
                    buttonText="Get Started"
                    description="Our patented Network Scrambling Mechanism neutralizes hackers' abilities to attack protected devices by scrambling all communications to controllers. This prevents unauthorized access and stops threats from spreading across your network."
                    buttonLink="#contact"
                />
            </div>
            <SectionWithImage
                imageSrc={reverseTrackingImg}
                imageAlt="Secure Privileged Access Today"
                subtitle="Reverse Tracking Mechanism"
                title="Trace Cyber Threats to Their Source"
                imagePosition="right"
                buttonText="Get Started"
                description="Our Reverse Tracking Technology tracks malicious activity back to its origin. This groundbreaking technology identifies attacks that hide in legitimate software, ensuring hidden threats are exposed and eliminated before they cause harm."
                buttonLink="#contact"
            />
            <SectionWithImage
                imageSrc={zeroTrustArchitecture}
                imageAlt="Secure Privileged Access Today"
                subtitle="Zero Trust Architecture"
                title="Trust No One, Verify Everything"
                imagePosition="left"
                buttonText="Get Started"
                bgClass={"bg-gray-50"}
                description="Our Zero Trust Architecture ensures no one can traverse your network without strict verification. By trusting nothing and verifying everything, we provide unparalleled protection against both known and unknown threats."
                buttonLink="#contact"
            />
            <SectionWithImage
                imageSrc={scrambledNetwork}
                imageAlt="Secure Privileged Access Today"
                subtitle="Scrambled Network Control State"
                title="Scramble Your Network with the Vortex OT Gateway"
                imagePosition="right"
                buttonText="Get Started"
                // bgClass={"bg-gray-50"}
                description="Using our Vortex OT Gateway and patented Chaos Algorithm, we scramble communications to create a unique Scrambled Network Control State. This makes your network communications secure and unreadable by unauthorized entities."
                buttonLink="#contact"
            />
            <SectionWithImage
                imageSrc={realTimeMonitoringImg}
                imageAlt="Secure Privileged Access Today"
                subtitle="Real-Time Monitoring with Bubbles"
                title="See Everything, Control Everything"
                imagePosition="left"
                buttonText="Get Started"
                bgClass={"bg-gray-50"}
                description="Our Bubbles feature offers a real-time digital twin of every protected OT device, giving you unparalleled insight into the status and performance of your industrial systems."
                buttonLink="#contact"
            />

            <WhyChooseUs {...benefitsData} />
            <SectionWithImage
                imageSrc={secureYourIndustrialSystems}
                imageAlt="Secure Your Industrial Systems Today"
                title="Secure Your Industrial Systems Today"
                imagePosition="right"
                buttonText="Contact Us"
                description="Don't leave your critical infrastructure vulnerable. Contact us now to learn how our OT Security solution can provide unbreachable protection for your industrial systems."
                bgClass="bg-gray-50"
                buttonLink="#contact"
            />
            <ContactForm />
        </div>
    )
}

export default OtSecurity