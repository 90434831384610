import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Accordion = ({ item, isOpen, setIsOpen }) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    return (
        <div className="">
            <button
                className="flex justify-between items-center w-full p-3 text-left text-gray-700 hover:text-gray-900 focus:outline-none"
                onClick={() => setIsAccordionOpen(!isAccordionOpen)}
            >
                <span>{item.name}</span>
                {item.subItems && (
                    <span className={`transform transition-transform ${isAccordionOpen ? 'rotate-180' : ''}`}>
                        ▼
                    </span>
                )}
            </button>
            {isAccordionOpen && item.subItems && (
                <div className="pl-4 pb-1">
                    {item.subItems.map((subItem) => (
                        <Link
                            key={subItem.name}
                            to={subItem.href}
                            className="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                            <spanc onClick={() => setIsOpen(!isOpen)}>{subItem.name}</spanc>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Accordion;
