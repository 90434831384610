import AboutSPIO from "./components/About";
import HeroSection from "./components/Herosection";
import Platform from "./components/Platform";
import ProductShowcase from "./components/Products";
import SectionWithImage from "./components/reusable/SectionWithImage";
import TrustBuildingSection from "./components/TrustBuildingSection";
import SB from "../src/assets/secure-your-business.png"
import CS from "../src/assets/cyber-security.png"
import ContactForm from "./components/reusable/ContactForm";
import { Link } from "react-router-dom";
import Button from "./components/reusable/Button";
// import NewHero from "./components/reusable/NewHero";
// import bg from '../src/assets/hero-background.jpg';
// import certificate from '../src/assets/certificate.png';

function App() {
  return (
    <div className="">
      <HeroSection />
      {/* <NewHero
        backgroundImage={bg}
        buttonLink="/#products"
        buttonText="Explore Our Solutions"
        certificationText="Tested and Endorsed by Global Cyber Security Experts"
        description="Protecting your business in real-time against evolving cyber threats."
        certificationBadge={certificate}
        title="Secure Your Future with Cutting-Edge Cybersecurity Solutions"
        subtitle="Social Up"
        contentDirection='left'
      /> */}
      <AboutSPIO />
      <ProductShowcase />
      <Platform />
      {/* cyber security consulting section */}
      {/* <SectionWithImage
        imageSrc={CS}
        subtitle={"Cyber Security Consulting"}
        imageAlt=""
        title="Protect Your Business with Expert Guidance"
        imagePosition="right"
        buttonText="Explore Our Service"
        description="Our consulting services are designed to safeguard your organization against evolving cyber threats. With tailored strategies and proactive defenses, we help you stay secure and compliant, giving you peace of mind in today’s digital landscape."
        bgClass="bg-gray-50"
        buttonLink="/services/cyber-security-consulting"
        id="services"
      /> */}
      <div id="services" className={`bg-gray-50 scroll-mt-16`}>
        <div className={` mx-auto px-6 md:px-8 py-10 md:py-14 `}>
          <div className={`flex flex-col md:flex-row items-center gap-8`}>
            <div className="w-full md:w-1/2 space-y-4 max-w-3xl md:order-1 order-2">
              <p className="text-red-600 font-semibold">Cyber Security Consulting</p>
              <h2 className="text-3xl md:text-5xl font-bold leading-tight">
                Protect Your Business with Expert Guidance
              </h2>
              <p className="text-gray-600 text-base pb-7 md:pb-8 ">
                Our consulting services are designed to safeguard your organization against evolving cyber threats. With tailored strategies and proactive defenses, we help you stay secure and compliant, giving you peace of mind in today’s digital landscape.
              </p>
              <Link to={"/services/cyber-security-consulting"}><Button>
                Explore Our Service
              </Button>
              </Link>
            </div>
            <div className="w-full md:w-1/2 order-1 md:order-2">
              <img
                src={CS}
                alt={"Cyber Security Consulting"}
                className="rounded-lg object-cover md:object-contain w-full h-full md:h-[550px] object-center aspect-square"
              />
            </div>
          </div>
        </div>
      </div>
      <TrustBuildingSection />
      {/* Secure your business section */}
      <SectionWithImage
        imageSrc={SB}
        imageAlt="Secure Your Business Today"
        title="Secure Your Business Today"
        imagePosition="left"
        buttonText="Contact Us"
        description="Take the first step towards protecting your business with our advanced solutions. Get in touch now for a personalized consultation and see how we can help safeguard your future."
        bgClass="bg-gray-50"
        buttonLink="#contact"
      />
      <ContactForm />
    </div>
  );
}

export default App;
