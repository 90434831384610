import { useLocation } from "react-router-dom"
import EDR from "../assets/product1.png"
import PAM from "../assets/product2.png"
import OT from "../assets/product3.png"
import IA from "../assets/product4.png"
import { useEffect, useRef } from "react"
import { Link } from "react-router-dom"

const ProductCard = ({ title, tagline, description, imageSrc, src }) => (
    <div className="flex-shrink-0 w-64 md:w-[calc(50%-1rem)] lg:w-[calc(33.333%-1rem)] xl:w-96 bg-white rounded-lg shadow-md overflow-hidden">
        <div className="">
            <img
                src={imageSrc}
                alt={title}
                className='w-full h-full object-cover'
            />
        </div>
        <div className="p-5 md:p-8">
            <h3 className="text-sm md:text-lg font-semibold mb-2">{title}</h3>
            <h4 className="text-base md:text-xl font-bold mb-4">{tagline}</h4>
            <p className="text-gray-600 mb-4 text-sm md:text-base line-clamp-3">{description}</p>
            <Link to={src} className="text-red-600 font-semibold flex items-center text-sm md:text-base">
                Explore Our Product
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.08125 4.99609L6.90625 6.17109L10.7229 9.99609L6.90625 13.8211L8.08125 14.9961L13.0813 9.99609L8.08125 4.99609Z" fill="#F30002" />
                </svg>
            </Link>
        </div>
    </div>
)

export default function ProductShowcase() {
    const location = useLocation();
    const sectionRef = useRef(null);

    useEffect(() => {
        // Check for section in the URL hash
        const sectionId = location.hash.replace("#", ""); // Remove the `#`
        if (sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    const products = [
        {
            title: 'EDR',
            tagline: 'Prevent Cyber Attacks in Real Time',
            description: 'Our EDR solution delivers powerful defense, detecting and neutralizing cyber threats in real-time to keep your business safe from attack.',
            imageSrc: EDR,
            src: "/product/edr"
        },
        {
            title: 'PAM',
            tagline: 'Master Your Privileged Access in a Secure Way',
            description: 'Our PAM solution gives you unparalleled control over privileged accounts, ensuring compliance without compromising on security.',
            imageSrc: PAM,
            src: "/product/pam"
        },
        {
            title: 'OT Security',
            tagline: 'Unbreachable Security for Industrial Systems',
            description: 'Shield controllers, safeguard data, and effectively defend against all cyber threats with our unbeatable OT Security solution.',
            imageSrc: OT,
            src: "/product/ot-security"
        },
        {
            title: 'Identity Assurance',
            tagline: 'Seamless, Secure Authentication in Real Time',
            description: 'Ensure only authorized users access systems with our real-time Identity Assurance, safeguarding critical assets.',
            imageSrc: IA,
            src: "/product/identity-assurance"
        }
    ]

    return (
        <section id='products' ref={sectionRef} className="bg-gray-100 pt-10 sm:pt-14 md:py-16  scroll-mt-14">
            <div className="px-4 md:px-12 mx-auto">
                <h2 className="text-red-600 text-sm font-semibold tracking-wide uppercase mb-2">Products</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8 pb-8 md:mb-12">
                    <h1 className="text-3xl sm:text-5xl font-bold text-gray-900">
                        Innovative Security Products for Your Business
                    </h1>
                    <p className="text-base sm:text-xl text-gray-600 self-end">
                        Explore our lineup of cutting-edge products designed to protect your business, streamline operations, and ensure secure access. From real-time cyber defense to seamless identity management, our solutions are built for modern challenges.
                    </p>
                </div>
                <div className="relative">
                    <div className="overflow-x-auto pb-16 pt-6 md:pb-2 -ml-4 sm:-mx-6 lg:-mx-8 no-scrollbar">
                        <div className="flex space-x-4 md:space-x-6 px-4 sm:px-6 lg:px-8">
                            {products.map((product) => (
                                <ProductCard key={product.title} {...product} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}