import Button from "./Button"

export default function NewHero({
    title,
    subtitle,
    description,
    buttonText,
    buttonLink,
    certificationBadge,
    certificationText,
    backgroundImage,
    contentDirection = "left"
}) {
    const isRightAligned = contentDirection === "right"

    return (
        <section className="relative min-h-[80vh] w-full overflow-hidden pt-10 sm:pt-24 md:pt-28 ">
            <div
                className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                style={{
                    backgroundImage: `linear-gradient(to ${isRightAligned ? 'left' : 'right'}, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4)), url(${backgroundImage})`
                }}
            />
            <div className="relative mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8 ">
                <div className={`max-w-2xl ${isRightAligned ? 'ml-auto' : ''}`}>
                    <div className="mb-2 md:mb-4">
                        <p className="text-base font-semibold text-white">{subtitle}</p>
                    </div>
                    <h1 className="mb-5">
                        <span className="block tracking-tight text-white text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
                            {title}
                        </span>
                    </h1>
                    <p className="mt-3 text-lg text-gray-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                        {description}
                    </p>
                    <div className={`mt-8 flex flex-col md:items-center gap-6 sm:flex-row ${isRightAligned ? 'sm:justify-end' : ''}`}>
                        <Button href={buttonLink} onClick={buttonLink}>
                            {buttonText}
                        </Button>
                        <div className={`flex flex-col md:flex-row md:items-center gap-4 ${isRightAligned ? 'sm:flex-row-reverse' : ''}`}>
                            <p className="text-xl text-gray-300 block md:hidden">
                                {certificationText}
                            </p>
                            {certificationBadge && (
                                <img
                                    src={certificationBadge}
                                    alt="Certification Badge"
                                    width={70}
                                    height={70}
                                    className="rounded-full"
                                />
                            )}
                            <p className="text-lg text-gray-300 hidden md:block">
                                {certificationText}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}