import React, { useEffect } from 'react'
import Hero from '../components/reusable/Hero'
import certificate from "../assets/certificate.png"

// images
// import PAMHero from "../assets/PAM-hero.png"
import PAMHero1 from "../assets/PAM-hero1.png"
import SectionWithImageAndScroll from '../components/reusable/SectionWithImageAndScroll'
import advancedAccess from "../assets/advanced-access-control.png"
import userManagementImg from "../assets/user-management-img.png"
import threatMonitoringImg from "../assets/proactive-threat-monitoring.png"
import operationalControlImg from "../assets/operational-control.png"
import effort from "../assets/effort.png"
import enhancedSecurityImg from "../assets/enhanced-security.png"
import securePrivileged from "../assets/secure-privileged-access.png"


// icon
import cyber from "../assets/cyber.svg"
import hierarchy from "../assets/hierarchy-structure.svg"
import timeManager from "../assets/time-manager.svg"
import userSetting from "../assets/user-setting.svg"

import findUser from "../assets/find-user.svg"
import wifi from "../assets/wifi.svg"
import nucleus from "../assets/nucleus.svg"
import devices from "../assets/devices.svg"

import monitor from "../assets/monitor.svg"
import notificationBell from "../assets/notification-bell.svg"
import onTime from "../assets/on-time.svg"
import intelligence from "../assets/intelligence.svg"

import stop from "../assets/stop.svg"
import realtimeAlert from "../assets/person.svg"
import report from "../assets/business-report.svg"
import cyberEye from '../assets/cyber-eye.svg'

import schedule from "../assets/schedule.svg"
import gear from "../assets/gear.svg"
import trend from "../assets/trend.svg"
import audit from "../assets/audit.svg"

import zeroTrust from '../assets/Zero-Trust.svg'
import refresh from '../assets/refresh.svg'
import safe from '../assets/safe.svg'
import insurance from '../assets/insurance.svg'

import peace from "../assets/peace-symbol.svg"
import WhyChooseUs from '../components/reusable/WhyChooseUs'

import increase from "../assets/increase.svg"
import expand from "../assets/expand.svg"
import appropved from "../assets/approved.svg"
import error from "../assets/error.svg"
import settings from "../assets/settings.svg"
import SectionWithImage from '../components/reusable/SectionWithImage'
import ContactForm from '../components/reusable/ContactForm'


const coreFeatures = [
    {
        icon: cyber,
        title: "Multi-Factor",
        subtitle: "Authentication (MFA)",
        description: "Adds an extra layer of security by requiring two or more credentials to log in."
    },
    {
        icon: hierarchy,
        title: "Roles-Based",
        subtitle: "Access Control",
        description: "Limit user permissions based on their role, ensuring only the right people access sensitive data."
    },
    {
        icon: timeManager,
        title: "Time-Based",
        subtitle: "Access Control",
        description: "Grant access during specific time windows to minimize exposure."
    },
    {
        icon: userSetting,
        title: "Just-in-Time",
        subtitle: "Access Control",
        description: "Give users access only when they need it, reducing security risks."
    },
]
const userManagement = [
    {
        icon: findUser,
        title: "Auto-Discover Users",
        subtitle: "and Devices",
        description: "Automatically detect and add new users and devices to your network."
    },
    {
        icon: wifi,
        title: "Unified Wi-Fi Access and Authentication ",
        subtitle: "Management",
        description: "Manage Wi-Fi authentication seamlessly across all users and devices."
    },
    {
        icon: nucleus,
        title: "Centralized",
        subtitle: "Onboarding",
        description: "Streamline the process of bringing new users onto the network."
    },
    {
        icon: devices,
        title: "Effortless Multi-Device",
        subtitle: "Management",
        description: "Manage all devices in one place for easy administration."
    },
]
const threatMonitoring = [
    {
        icon: monitor,
        title: "Live Session",
        subtitle: "Monitoring",
        description: "Track user activity in real-time to catch any potential threats."
    },
    {
        icon: notificationBell,
        title: "PAM Bypass",
        subtitle: "Alerts",
        description: "Get instant alerts when someone tries to bypass privileged access on major systems like Windows and Linux."
    },
    {
        icon: onTime,
        title: "Real-Time",
        subtitle: "Alerts",
        description: "Stay updated on unauthorized access attempts and react quickly."
    },
    {
        icon: intelligence,
        title: "Threat",
        subtitle: "Intelligence",
        description: "Benefit from our insights into evolving security threats."
    }
]
const operationalControl = [
    {
        icon: stop,
        title: "Advanced",
        subtitle: "Restrictions",
        description: "Limit access to SSH, file transfers, and remote logins to secure sensitive data."
    },
    {
        icon: realtimeAlert,
        title: "Real-Time Alerts on",
        subtitle: "Unauthorized Activity",
        description: "Know instantly when something suspicious happens."
    },
    {
        icon: report,
        title: "Detailed",
        subtitle: "Reports",
        description: "Access comprehensive reports to monitor all system activities."
    },
    {
        icon: cyberEye,
        title: "Full",
        subtitle: "Visibility",
        description: "Maintain a clear view of all network activity to ensure smooth operations."
    }
]
const effortlessCompliance = [
    {
        icon: schedule,
        title: "Scheduled",
        subtitle: "Reports",
        description: "Automatically generate compliance reports to stay audit-ready."
    },
    {
        icon: gear,
        title: "Automated Compliance",
        subtitle: "Tools",
        description: "Ensure you meet all regulatory requirements effortlessly."
    },
    {
        icon: trend,
        title: "Analytics &",
        subtitle: "Trend Reports",
        description: "Gain insights into how your security posture evolves over time."
    },
    {
        icon: audit,
        title: "Detailed Audit &",
        subtitle: "Compliance Reports",
        description: "Access detailed reports to show your compliance status during audits."
    }
]
const enhancedSecurity = [
    {
        icon: zeroTrust,
        title: "Zero Trust",
        subtitle: "Security Model",
        description: "No one gains access without proper authentication, no matter their location."
    },
    {
        icon: refresh,
        title: "Dynamic Password",
        subtitle: "Management",
        description: "Automatically rotate passwords to minimize the risk of credential theft."
    },
    {
        icon: safe,
        title: "Hardened PAM",
        subtitle: "Solution",
        description: "Built to withstand even the most sophisticated attacks."
    },
    {
        icon: insurance,
        title: "No-Log",
        subtitle: "Access",
        description: "Protect sensitive logs from unauthorized access."
    }
]



const benefitsData = {
    layout: 'layout1',
    title: 'Benefits of Choosing Us',
    mainHeading: 'Security, Simplicity, and Peace of Mind',
    description: 'Our PAM solution does more than just protect your network—it transforms how your business operates. By integrating advanced security features and user-friendly management tools, we help you save time, reduce risk, and stay compliant. With our comprehensive approach to privileged access management, you can focus on what matters most—growing your business.',
    benefits: [
        {
            icon: increase,
            title: 'Increased Security',
            description: 'Protect against internal and external threats with multi-layered defenses.'
        },
        {
            icon: peace,
            title: 'Time Savings',
            description: 'Automate tedious tasks like user onboarding, compliance reporting, and threat monitoring.'
        },
        {
            icon: expand,
            title: 'Scalability',
            description: 'Easily manage more users and devices as your business grows without compromising security.'
        },
        {
            icon: appropved,
            title: 'Simplified Compliance',
            description: 'Meet regulatory requirements effortlessly with built-in tools and scheduled reporting.'
        },
        {
            icon: error,
            title: 'Reduced Risk',
            description: 'Limit exposure with precise access control, dynamic password management, and real-time threat monitoring.'
        },
        {
            icon: settings,
            title: 'Operational Efficiency',
            description: 'Centralize management for streamlined workflows and faster decision-making.'
        },
    ]
}


const PAM = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Hero
                direction='left'
                title='Master Your Privileged Access in a Secure, Compliant, and Effortless Way.'
                subtitle='Privilege Access Management (PAM)'
                description='Our PAM solution gives you unparalleled control over privileged accounts, ensuring compliance without compromising on security.'
                buttonText='Explore Our Solutions'
                certificationText='Tested and Endorsed by Global Cyber Security Experts'
                certificationLogo={certificate}
                heroImg={PAMHero1}
                heroAlt={"Cyber Security Illustration"}
                buttonLink="/#products"
            />
            <div>
                <div className="text-center pt-6 sm:pt-10 md:pt-12">
                    <h2 className="text-red-600 font-semibold mb-2">Core Features</h2>
                    <h1 className="text-5xl font-bold mb-4 leading-tight">Unleashing the Power of PAM</h1>
                </div>
                <SectionWithImageAndScroll
                    imageSrc={advancedAccess}
                    imageAlt="Business protection"
                    title="Manage Access Like Never Before"
                    subtitle='Advanced Access Control'
                    description="Take full control of access management across your network with multi-layered security tools. Our solution ensures that only the right people access your systems, exactly when they need to."
                    buttonText="Get Started"
                    onButtonClick={() => console.log("Button clicked!")}
                    features={coreFeatures}
                    imageSize="style3"
                    buttonLink="#contact"
                />
            </div>
            <SectionWithImageAndScroll
                imageSrc={userManagementImg}
                imageAlt="Seamless User Management"
                title="Streamline Your User"
                subtitle='Management'
                description="Save time and reduce complexity with automated user onboarding and device management tools. Our platform handles everything from discovering new devices to authenticating access with ease."
                buttonText="Get Started"
                onButtonClick={() => console.log("Button clicked!")}
                features={userManagement}
                imageSize="style3"
                imagePosition="right"
                buttonLink="#contact"
            />
            <SectionWithImageAndScroll
                imageSrc={threatMonitoringImg}
                imageAlt="Seamless User Management"
                title="Stay Ahead of Every Threat"
                subtitle='Proactive Threat Monitoring'
                description="Receive instant alerts for suspicious activity across your network. Our advanced monitoring tools ensure you stay informed and ready to act, so threats are neutralized before they cause damage."
                onButtonClick={() => console.log("Button clicked!")}
                features={threatMonitoring}
                imageSize="style3"
                imagePosition="left"
                buttonLink="#contact"
            />
            <SectionWithImageAndScroll
                imageSrc={operationalControlImg}
                imageAlt="Seamless User Management"
                title="Control, Monitor, and Protect"
                subtitle='Complete Operational Control'
                description="Stay in control of your operations with detailed restrictions and monitoring tools. Whether it's SSH, file transfers, or remote logins, you’ll know exactly what’s happening at all times."
                onButtonClick={() => console.log("Button clicked!")}
                features={operationalControl}
                imageSize="style3"
                imagePosition="right"
                buttonLink="#contact"
            />
            <SectionWithImageAndScroll
                imageSrc={effort}
                imageAlt="Meet Standards, Reduce Effort"
                title="Meet Standards, Reduce Effort"
                subtitle='Effortless Compliance'
                description="Compliance doesn't have to be a hassle. With our automated tools, you can generate compliance reports, track regulatory changes, and stay ahead of audits with minimal effort."
                onButtonClick={() => console.log("Button clicked!")}
                features={effortlessCompliance}
                imageSize="style3"
                imagePosition="left"
                buttonLink="#contact"
            />
            <SectionWithImageAndScroll
                imageSrc={enhancedSecurityImg}
                imageAlt="Seamless User Management"
                title="Fortify Your Security at Every Level"
                subtitle='Enhanced Security Layers'
                description="Our Zero Trust approach ensures no unauthorized access, period. With advanced security features like dynamic password management, you can trust that your systems are locked down against all forms of attack."
                onButtonClick={() => console.log("Button clicked!")}
                features={enhancedSecurity}
                imageSize="style3"
                imagePosition="right"
                buttonLink="#contact"
            />
            <WhyChooseUs {...benefitsData} />
            <SectionWithImage
                imageSrc={securePrivileged}
                imageAlt="Secure Privileged Access Today"
                title="Secure Privileged Access Today"
                imagePosition="right"
                buttonText="Contact Us"
                description="Control and secure privileged access with ease. Prevent unauthorized access and enhance your security framework. Start securing today."
                buttonLink="#contact"
            />
            <ContactForm />
        </div>
    )
}

export default PAM