// import React from 'react';

// const Button = ({
//     children,
//     onClick,
//     type = 'button',
//     className = '',
//     variant = 'primary',
//     size = 'md',
//     disabled = false
// }) => {
//     const baseStyles = 'rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2';
//     const sizeStyles = {
//         sm: 'px-4 py-2 text-sm',
//         md: 'px-6 py-2 text-base',
//         lg: 'px-8 py-4 text-lg',
//     };
//     const variantStyles = {
//         primary: 'bg-red-600 text-white hover:bg-red-700',
//         secondary: 'bg-gray-600 text-white hover:bg-gray-700',
//         outline: 'border border-red-600 text-red-600 hover:bg-red-600 hover:text-white',
//     };

//     return (
//         <button
//             type={type}
//             onClick={onClick}
//             className={`${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]} ${className} transition-colors whitespace-nowrap`}
//             disabled={disabled}
//         >
//             {children}
//         </button>
//     );
// };

// export default Button;

import React from 'react';

const Button = ({
    children,
    onClick,
    href,
    type = 'button',
    className = '',
    variant = 'primary',
    size = 'md',
    disabled = false,
}) => {
    const baseStyles = 'w-fit rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors whitespace-nowrap';
    const sizeStyles = {
        sm: 'px-4 py-2 text-sm',
        md: 'px-6 py-2 text-base',
        lg: 'px-8 py-4 text-lg',
    };
    const variantStyles = {
        primary: 'bg-red-600 text-white hover:bg-red-700',
        secondary: 'bg-gray-600 text-white hover:bg-gray-700',
        outline: 'border border-red-600 text-red-600 hover:bg-red-600 hover:text-white',
    };
    const disabledStyles = 'opacity-50 cursor-not-allowed';

    // Render an <a> tag if `href` is provided
    if (href) {
        return (
            <a
                href={href}
                className={`palanquin-bold ${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]} ${className}`}
            >
                {children}
            </a>
        );
    }

    // Otherwise, render a <button>
    return (
        <button
            type={type}
            onClick={onClick}
            className={`palanquin-bold ${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]} ${disabled ? disabledStyles : ''
                } ${className}`}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
