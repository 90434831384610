import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function TermsOfService() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="flex flex-col min-h-screen max-w-4xl mx-auto w-11/12">
            <div className="mt-16 md:mt-16 flex-grow px-3 py-6 rounded md:p-8 lg:p-10">
                <h1 className="text-xl md:text-2xl font-bold mb-6">Terms and Services</h1>

                <section className="mb-6">
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Introduction</h2>
                    <p className="text-base mb-4">
                        Welcome to <Link target='_blank' to={"https://spiolabs.com/"} className='text-red-600'>https://spiolabs.com/</Link> (the "Website"). These Terms and Conditions
                        ("Terms") govern your use of our Website. By accessing or using the Website, you agree to
                        comply with and be bound by these Terms. If you do not agree with these Terms, please do not
                        use our Website.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Use of the Website</h2>
                    <ol className="list-decimal pl-6 mb-4">
                        <li className="mb-2">
                            <span className="font-semibold">Eligibility:</span> You must be at least 18 years old to use our Website. By using the Website,
                            you represent and warrant that you are at least 18 years of age.
                        </li>
                        <li className="mb-2">
                            <span className="font-semibold">License to Use:</span> We grant you a limited, non-exclusive, non-transferable, and revocable
                            license to access and use the Website for personal, non-commercial purposes, subject to
                            these Terms.
                        </li>
                    </ol>
                </section>

                <section className="mb-6">
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Intellectual Property</h2>
                    <ol className="list-decimal pl-6 mb-4">
                        <li className="mb-2">
                            <span className="font-semibold">Ownership:</span> All content, features, and functionality on the Website, including but not
                            limited to text, graphics, logos, and images, are the property of {" "}
                            <Link target='_blank' to={"https://spiolabs.com/"} className='text-red-600'>
                                https://spiolabs.com/
                            </Link>
                            {" "} or its content suppliers and are protected by copyright,
                            trademark, and other intellectual property laws.
                        </li>
                        <li className="mb-2">
                            <span className="font-semibold">Restrictions:</span> You may not reproduce, distribute, modify, create derivative works of,
                            publicly display, publicly perform, republish, download, store, or transmit any of the
                            material on our Website without our prior written consent.
                        </li>
                    </ol>
                </section>

                <section className="mb-6">
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Prohibited Uses</h2>
                    <p className="mb-4">You agree not to use the Website:</p>
                    <ol className="list-decimal pl-6 mb-4">
                        <li className="mb-2">
                            In any way that violates any applicable federal, state, local, or international law or
                            regulation.
                        </li>
                        <li className="mb-2">
                            To engage in any conduct that restricts or inhibits anyone's use or enjoyment of the
                            Website.
                        </li>
                        <li className="mb-2">
                            To impersonate or attempt to impersonate <Link to={"https://spiolabs.com/"} target='_blank' className='text-red-600'>
                                https://spiolabs.com/
                            </Link>, a {" "}
                            <Link to={"https://spiolabs.com/"} target='_blank' className='text-red-600'>
                                https://spiolabs.com/
                            </Link> employee, another user, or any other person or entity.
                        </li>
                    </ol>
                </section>

                <section className="mb-6">
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Disclaimer of Warranties</h2>
                    <p className="mb-4">
                        The Website is provided on an "as is" and "as available" basis. We make no warranties,
                        expressed or implied, regarding the Website, including but not limited to its accuracy, reliability,
                        or availability.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Limitation of Liability</h2>
                    <p className="mb-4">
                        To the fullest extent permitted by law, <Link to={"https://spiolabs.com/"} target='_blank' className='text-red-600'>
                            https://spiolabs.com/
                        </Link> shall not be liable for any
                        damages of any kind arising from the use of or inability to use the Website, including but not
                        limited to direct, indirect, incidental, punitive, and consequential damages.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Changes to Terms</h2>
                    <p className="mb-4">
                        We reserve the right to update or modify these Terms at any time without prior notice. Your
                        continued use of the Website following any changes indicates your acceptance of the new Terms.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Governing Law</h2>
                    <p className="mb-4">
                        These Terms are governed by and construed in accordance with the laws of Texas/USA, without regard to its conflict of law principles.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Contact Us</h2>
                    <p className="mb-4">
                        If you have any questions about these Terms, please contact us at <a href='mailto:https://spiolabs.com/'>https://spiolabs.com/</a>
                    </p>
                </section>
            </div>
        </div>
    );
}
